import { FC, useState, useCallback, useEffect } from 'react';
import { Box, TextField, InputAdornment, Typography } from '@mui/material';
import { DEFAULT_PAYBACK_PERIOD_OPTIONS, ROAS_PAYBACK_PERIOD_VALUE } from '../../../constants/constants';
import { CalculatorChartData, CalculatorChartDataItem } from '../../../stores/FundingCalculator/types/fundingCalculator';

type CalculatorChartPropType = {
    paybackPeriod: number;
    setPaybackPeriod?: React.Dispatch<React.SetStateAction<number>>;
    monthlyPaybackValues: CalculatorChartData;
    handleChangeMonthlyPaybackValues: (data: CalculatorChartData) => void;
    isDynamicInterestCalculation: boolean;
};

const CalculatorChartInput: FC<CalculatorChartPropType> = (props) => {
    const {
        paybackPeriod,
        setPaybackPeriod = (val: number) => null,
        monthlyPaybackValues,
        handleChangeMonthlyPaybackValues,
        isDynamicInterestCalculation
    } = props;
    const [userInputs, setUserInputs] = useState<CalculatorChartData>([]);
    const [error, setError] = useState<{ month: number; text: string; } | null>(null);

    // Hook to update both monthlyPaybackValues and userInputs based on paybackPeriod defaults
    useEffect(() => {
        if (paybackPeriod) {
            const defaultValues = DEFAULT_PAYBACK_PERIOD_OPTIONS[paybackPeriod];
            const data = defaultValues.map((item: CalculatorChartDataItem) => {
                return {
                    month: item.month,
                    value: item.value,
                };
            });

            setUserInputs(data);
            handleChangeMonthlyPaybackValues(data);
        }

    }, [paybackPeriod]);

    // Hook to update monthlyPaybackValues based on userInputs
    useEffect(() => {
        if (userInputs.length > 0) {
            const inputValues = userInputs.filter(input => input.month <= paybackPeriod);
            handleChangeMonthlyPaybackValues(inputValues);
        }
    }, [userInputs]);

    // Hook to load userInputs based on monthlyPaybackValues
    useEffect(() => {
        if (userInputs.length === 0) {
            setUserInputs(monthlyPaybackValues);
        }
    }, [monthlyPaybackValues]);

    // Hook to handle validation and error states for user inputs
    useEffect(() => {
        // Initialize a debounce timer
        const debounceTimer = setTimeout(() => {
            if (userInputs.length > 0) {
                let hasReached100 = false;
                let startingInterest = 2;
                let delta = 1;
                let paidBack = false;
                let isValid = true;

                for (let i = 0; i < userInputs.length; i++) {
                    const { value, month } = userInputs[i];

                    // Rule: At least one value must be >= 100
                    if (value >= 100) {
                        hasReached100 = true;
                    }

                    // Rule: No values after a 100+ value can be less than that value
                    if (hasReached100 && i > 0 && value < userInputs[i - 1].value) {
                        isValid = false;
                        setError({ month: month, text: 'Cumulative ROAS for each month should be higher than the previous month.' });
                        break;
                    }

                    // Rule: Month 1 is 2% and elapsed months till payback are 1% each
                    const expectedPaybackRoas = 100 + (startingInterest + delta * (month - 1));
                    if (hasReached100 && value >= expectedPaybackRoas && isDynamicInterestCalculation && !paidBack) {
                        paidBack = true;
                    }
                }

                if (!paidBack && isDynamicInterestCalculation) {
                    setError({ month: 999, text: "This cohort cannot payback principal plus cash cost, please try with higher ROAS inputs." });
                } else if (!hasReached100) {
                    setError({ month: 999, text: "Cumulative ROAS should cross 100% for the cohort to payback. If your cohorts take longer, try increasing the payback period." });
                } else if (isValid) {
                    setError(null);
                }
            }
        }, 800);

        // Clean up the timer if the dependencies change before the timeout completes
        return () => clearTimeout(debounceTimer);
    }, [userInputs, isDynamicInterestCalculation]);
    // Function to record and userInputs
    const handleInputChange = useCallback(
        (month: number, newValue: string) => {
            let value = parseInt(newValue.replace(/[^0-9]/g, ''));
            if (isNaN(value)) {
                value = 0;
            }
            if (value >= 0 && value <= 999) {
                setUserInputs((prevInputs) => {
                    const existingInputIndex = prevInputs.findIndex(input => input.month === month);
                    const updatedInputs = [...prevInputs];
                    if (existingInputIndex >= 0) {
                        updatedInputs[existingInputIndex] = { month, value };
                    } else {
                        updatedInputs.push({ month, value });
                    }
                    return updatedInputs;
                });
            }
        },
        [],
    );

    const getMonthValue = (month: number) => {
        const monthValue = userInputs.find(input => input.month === month)?.value;

        return monthValue !== undefined ? `${monthValue.toFixed(0)}` : '';
    };

    const getAdornmentPosition = (month: number) => {
        const monthValue = userInputs.find(input => input.month === month)?.value.toString();
        if (monthValue) {
            if (monthValue.length > 2)
                return '50px'
            else if (monthValue.length > 1) return '60px'
            else return '70px'
        }

    }

    const getErrorStatus = (month: number) => (error?.month && (month >= error.month || error.month === 999));

    return (
        <>
            <Box id="calculator_landing_body_chart_inputs">
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'wrap',
                        mb: '1rem',
                        gap: "1rem"
                    }}
                >
                    {ROAS_PAYBACK_PERIOD_VALUE.filter((option) => option.value <= paybackPeriod)
                        .map((option) => (
                            <Box
                                id={`Month-${option.value}-currency-input-field`}
                                key={option.value}
                                sx={{
                                    width: '7rem',
                                    height: "max-content",
                                    display: "flex",
                                    flexDirection: "column",
                                    py: "0.5rem",
                                    px: "0.25rem",
                                    border: `1px solid ${getErrorStatus(option.value) ? "#BA1A1A" : "#16293699"}`,
                                    borderRadius: "1rem",
                                }}
                            >
                                <Typography
                                    component="span"
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: '0.6rem',
                                        color: `${getErrorStatus(option.value) ? "#BA1A1A" : "#162936"}`,
                                        pl: "0.5rem",
                                        pb: "0.5rem"
                                    }}
                                    fontFamily="Manrope, sans-serif"
                                >
                                    {`MONTH ${option.value}`}
                                </Typography>
                                <TextField
                                    id={`Month-${option.value}-textfield`}
                                    sx={{
                                        width: '100%',
                                        border: 'none',
                                        input: {
                                            color: `${getErrorStatus(option.value) ? "#BA1A1A" : "#162936"}`,
                                            fontSize: '1rem',
                                            paddingLeft: '0.5rem',
                                            paddingRight: '0',
                                            paddingTop: '0',
                                            paddingBottom: '0',
                                            textAlign: 'left',
                                            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                                WebkitAppearance: 'none',
                                                margin: 0,
                                            },
                                            '&[type=number]': {
                                                MozAppearance: 'textfield',
                                            }
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: 'transparent',
                                            '& fieldset': {
                                                border: 'none',
                                                p: 0
                                            },
                                            '&:hover fieldset': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                    type="text"
                                    onChange={(e) => handleInputChange(option.value, e.target.value)}
                                    value={getMonthValue(option.value)}
                                    InputProps={{
                                        endAdornment: getMonthValue(option.value) ? (
                                            <InputAdornment
                                                disableTypography
                                                position="end"
                                                sx={{
                                                    right: getAdornmentPosition(option.value),
                                                    position: "absolute",
                                                    color: `${getErrorStatus(option.value) ? "#BA1A1A" : "#162936"}`,
                                                    fontFamily: 'Manrope, sans-serif',
                                                    fontWeight: 550
                                                }}
                                            >
                                                %
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                />
                            </Box>
                        ))}
                </Box>
                <Typography fontSize="0.75rem" fontWeight={550} sx={{ color: "#BA1A1A", mb: "1rem" }}>
                    {error?.text}
                </Typography>
            </Box>
        </>
    );
}

export default CalculatorChartInput;
