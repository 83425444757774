import { createTheme } from "@mui/material/styles";

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    x1400: true;
  }

  interface Palette {
    custom_background: string;
    custom_field_background: string;
    custom_sidebar: {
      background: string;
    };
    custom_menu: {
      primary_background: string;
      secondary_background: string;
      selected: string;
      hover: string;
      font: string;
    };
    funding_calculator: {
      landing_page: {
        background: string;
        font: string;
        header_background: string;
      },
      main_page: {
        background: string;
        font: string;
        secondary_font: string;
      }
    }
  }

  interface PaletteOptions {
    custom_background?: string;
    custom_field_background?: string;
    custom_sidebar?: {
      background?: string;
    };
    custom_menu?: {
      hover?: string;
      font?: string;
    };
    funding_calculator?: {
      landing_page?: {
        background?: string;
        font?: string;
        header_background?: string;
      },
      main_page?: {
        background?: string;
        font?: string;
        secondary_font?: string;
      }
    };
  }

  interface Theme {
    sizes: {
      mobile: {
        marginTop: string;
      };
    };
  }

  interface ThemeOptions {
    sizes?: {
      mobile?: {
        marginTop?: string;
      };
    };
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      x1400: 1400,
    },
  },
  palette: {
    primary: {
      main: "#2F736E",
    },
    secondary: {
      main: "#2E4A4F",
    },
    error: {
      main: '#FF6347',
      light: '#FFFFFF',
      dark: '#BA1A1A',
      contrastText: '#FFFFFF',
    },
    custom_sidebar: {
      background: "#162C36",
    },
    custom_menu: {
      hover: "#9AD6D1",
      font: "#141F00",
    },
    custom_background: "#F5F5F5",
    custom_field_background: "#ECEFF1",
    funding_calculator: {
      landing_page: {
        background: "#F5F5F5",
        font: "#002F36",
        header_background: "#F5F5F5",
      },
      main_page: {
        background: "#F5F5F5",
        font: "#6B6B6B",
        secondary_font: "#E0E0E0",
      }
    },
  },
  typography: {
    fontFamily: "Manrope, sans-serif",
  },
  sizes: {
    mobile: {
      marginTop: '48px'
    }
  }
});

export default theme;
