import { CreateFundingCalculationPayload } from '../../api/fundingCalculator';
import { useCreateFundingCalculataionMutation } from '../../api/fundingCalculator';

const useCreateFundingCalculation = () => {
  const [createFundingCalculataion] = useCreateFundingCalculataionMutation();
  const createCalculataion = async (payload: CreateFundingCalculationPayload) => {
    try {
      const response = await createFundingCalculataion(payload).unwrap();
      return response;
    } catch (err) {
      console.error('Error while creating funding calculataion :', err);
    }
  };

  return { createCalculataion };
};

export default useCreateFundingCalculation;
