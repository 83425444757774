import { FC } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { genericTexts } from "@utils/translations/en";

type PopoverType = {
  title: string;
  subTitle: string;
  primaryButton: string;
  onClose: () => void;
  onAction: () => void;
};
const Popover: FC<PopoverType> = (props) => {
  const theme = useTheme();
  const { title, subTitle, primaryButton, onClose, onAction } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00000070",
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          borderRadius: "28px",
          height: "200px",
          p: "24px",
          width: "fit-content",
          maxWidth: "400px",
          position: "relative",
        }}
      >
        <Typography color="#162936" fontSize={24} fontWeight={700}>
          {title}
        </Typography>
        <Typography color="#6C6C6C" fontSize={14} fontWeight={500}>
          {subTitle}
        </Typography>
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            flexDirection: "row-reverse",
            gap: 2,
            width: "100%",
            right: "16px",
            bottom: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: theme.palette.primary.main,
              borderRadius: "100px",
              color: theme.palette.common.white
            }}
            onClick={onAction}
          >
            {primaryButton}
          </Button>
          <Button
            onClick={onClose}
            sx={{
              background: theme.palette.common.white,
              borderRadius: "100px",
              color: theme.palette.primary.main
            }}
          >
            {genericTexts.Cancel}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Popover;
