import { FC, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDownOutlined";
import AddIcon from '@mui/icons-material/Add';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import FilterRow from "./FilterRow";
import { SubmoduleFilterMap } from "../../../stores/Modules/types/modules";
import { genericTexts, titles } from "@utils/translations/en";
import useSubmoduleFilterValues from "@hooks/api/useSubmoduleFilterValues";

type FilterSectionPropType = {
  moduleName: string;
  moduleId: string;
  subModuleId: string;
  filterMap: SubmoduleFilterMap | null;
  close?: () => void;
  handleFilterSave: (val: any) => void;
  selectedFilters: string[];
};

const FilterSection: FC<FilterSectionPropType> = (props) => {
  const theme = useTheme();
  const { filterMap, close = () => { }, handleFilterSave, moduleName, moduleId, subModuleId, selectedFilters } = props;
  const { addFilterValue } = useSubmoduleFilterValues();
  const [selectedOptionArray, setSelectedOptionsArray] = useState<any[]>([]);
  const [showAddGameIdPopup, setShowAddGameIdPopup] = useState<boolean>(false);
  const [newGameId, setNewGameId] = useState<string>('');
  const [newGameError, setNewGameError] = useState<boolean>(false);

  useEffect(() => {
    if (newGameId && !newGameError && filterMap) {
      const currentGameIds = Object.values(filterMap)?.[0].map((f) => (f.filterValue));
      if (currentGameIds.includes(newGameId)) {
        setNewGameError(true);
      }
    } else {
      setNewGameError(false);
    }
  }, [newGameId]);

  const mapSubmoduleFilters = () => {
    if (filterMap) {
      return Object.values(filterMap).map((f, index) => ({
        key: Object.keys(filterMap)[index],
        value: f
      }));
    }
    return [];
  }

  const handleAddNewGameId = async () => {
    if (showAddGameIdPopup && newGameId && filterMap) {
      const res = await addFilterValue({
        moduleId,
        submoduleId: subModuleId,
        filterId: Object.values(filterMap)?.[0]?.[0]?.submoduleFilterId,
        values: [newGameId]
      });

      if (Boolean(res.code)) {
        setNewGameId("");
        setShowAddGameIdPopup(false);
      }
    }
  };

  return (
    <Box
      sx={{
        m: 0,
        width: "360px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        p: "10px",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        background: theme.palette.common.white,
      }}
    >
      <Box sx={{ height: 64, display: "flex", justifyContent: "space-between", alignItems: "center", ml: "0.5rem" }}>
        <Typography fontSize={22} fontWeight={700} sx={{ color: "#6C6C6C" }}>{titles.ADD_FILTERS}</Typography>
        <Box
          sx={{
            width: 48,
            height: 48,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CloseIcon onClick={() => close()} sx={{ cursor: "pointer" }} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          color={theme.palette.custom_menu.font}
          fontSize={20}
          sx={{
            pl: "0.5rem",
            fontWeight: 700,
            color: "#162936"
          }}
        >
          {moduleName}
        </Typography>
        {!showAddGameIdPopup && (<Button
          sx={{ color: "#51B8B0", textTransform: "none", p: 0 }}
          onClick={() => setShowAddGameIdPopup(true)}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem" }}>
            <AddIcon
              htmlColor="#51B8B0"
              sx={{
                height: '1rem',
                width: '1rem',
                cursor: "pointer",
                backgroundColor: theme.palette.common.white,
              }}
            />
            <Typography
              sx={{
                color: "#51B8B0",
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              Add Game
            </Typography>
          </Box>
        </Button>)}
      </Box>
      {showAddGameIdPopup && (
        <Box
          sx={{
            height: "max-content",
            width: "100%",
            p: "0.5rem"
          }}
        >
          <TextField
            label="Enter Game Name"
            id="game-id-label"
            value={newGameId}
            onChange={(e) => setNewGameId(e.target.value)}
            sx={{
              width: "100%",
              "& .MuiInputLabel-root.Mui-focused": {
                color: newGameError ? theme.palette.error.main : theme.palette.primary.main,
              },
              "& .MuiInputBase-root": {
                color: "#162936",
                fontSize: "1rem",
                fontWeight: 550,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: newGameError ? theme.palette.error.main : theme.palette.primary.main,
                },
                "&:hover fieldset": {
                  borderColor: newGameError ? theme.palette.error.main : theme.palette.primary.main,
                },
                "&.Mui-focused fieldset": {
                  borderColor: newGameError ? theme.palette.error.main : theme.palette.primary.main,
                },
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 2,
              width: "100%",
              py: "0.5rem"
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: theme.palette.primary.main,
                borderRadius: "2rem",
                color: theme.palette.common.white,
                px: "2rem",
                textTransform: "none",
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}
              onClick={() => handleAddNewGameId()}
              disabled={!newGameId || newGameError}
            >
              {genericTexts.Save}
            </Button>
            <Button
              onClick={() => {
                setShowAddGameIdPopup(false);
                setNewGameId("");
              }}
              sx={{
                background: theme.palette.common.white,
                borderRadius: "100px",
                color: theme.palette.primary.main,
                textTransform: "none"
              }}
            >
              {genericTexts.Cancel}
            </Button>
          </Box>
        </Box>
      )}
      <Box sx={{ overflowY: 'auto', paddingBottom: 8, opacity: showAddGameIdPopup ? "0.5" : "1", pointerEvents: showAddGameIdPopup ? "none" : "cusor" }}>
        {mapSubmoduleFilters()?.map(
          (
            filter,
            index
          ) => (
            <Box
              key={filter.key}
              sx={{
                marginBottom: 1,
                borderRadius: '1rem',
                background: theme.palette.common.white,
              }}
            >
              <Accordion
                key={filter.key}
                defaultExpanded={index === 0}
                sx={{
                  boxShadow: "none",
                  "&::before": {
                    content: '""',
                    backgroundColor: "white",
                  },
                }}
                disableGutters
              >
                <AccordionSummary
                  sx={{
                    color: "#1D1B20",
                    py: "10px",
                    pl: "10px",
                    fontWeight: 500,
                    minHeight: 'unset',
                    height: '100%',
                    '&.Mui-expanded': {
                      minHeight: 'unset',
                      height: '100%',
                    },
                    '& .MuiAccordionSummary-content': {
                      margin: 0,
                    },
                    '&.Mui-expanded .MuiAccordionSummary-content': {
                      margin: 0,
                    },
                  }}

                  expandIcon={<ArrowDropDownIcon />}
                >
                  {filter.key}
                </AccordionSummary>
                <FilterRow
                  filter={{
                    name: filter.key,
                    options: filter.value
                  }}
                  selectedFilters={selectedFilters}
                  setSelectedOptionsArray={setSelectedOptionsArray}
                />
              </Accordion>
              <Box sx={{ height: "1px", backgroundColor: "rgba(0,0,0,0.12)" }} />
            </Box>
          )
        )}
      </Box>
      <Box
        sx={{
          position: "fixed",
          zIndex: 20,
          bottom: 0,
          right: 0,
          height: 73,
          width: "360px",
          border: "1px solid #CCC2DC",
          px: 4,
          py: 2,
          display: "flex",
          flexDirection: "row",
          gap: 2,
          background: theme.palette.common.white,
          marginBottom: "1vh",
          marginRight: "1vh",
          borderRadius: "0 0 1rem 1rem"
        }}
      >
        <Button
          variant="contained"
          sx={{
            background: theme.palette.primary.main,
            color: "#F6F8F9",
            borderRadius: "100px",
            textTransform: "none",
            px: "1.5rem",
            '&:hover': {
              background: theme.palette.primary.main,
            },
          }}
          onClick={() => {
            handleFilterSave(selectedOptionArray);
            close();
          }}
        >
          {genericTexts.Save}
        </Button>
        <Button
          variant="outlined"
          onClick={() => close()}
          sx={{
            borderRadius: "100px",
            color: "#6C6C6C",
            borderColor: theme.palette.secondary.main,
            '&:hover': {
              borderColor: theme.palette.secondary.main,
            },
            textTransform: "none"
          }}
        >
          {genericTexts.Cancel}
        </Button>
      </Box>
    </Box>
  );
};

export default FilterSection;
