import apiRoutes from '../constants/apiRoutes';
import { noAuthBaseApi } from '../services/api';

export interface CreateFundingCalculationPayload {
  paybackPeriod?: number;
  gameUrl: string;
  launchDate?: string;
  avgMonthlyMarketingSpend: number;
  requesterName: string;
  companyName: string;
  businessEmail: string;
  suppressNotification: boolean;
  cumulativeRoas?: {
    month: number;
    cumulativeRoas: number;
  }[];
  attributedChannel?: string | null;
}

export interface UpdateFundingCalculationPayload {
  id: string;
  paybackPeriod: number;
  avgMonthlyMarketingSpend: number;
  cumulativeRoas: {
    month: number;
    cumulativeRoas: number;
  }[];
  investorFundingPercentage: number;
  netRevenue: number;
  latestMonthCashBalance: number;
  operatingExpenses: number;
  monthlyRevenueChurn: number;
  monthlySpendIncrease: number;
  isDynamicInterestCalculation: boolean;
}

const fundingCalculator = noAuthBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getfundingCalculator: builder.query<any, any>({
      query: ({ id, isEmail }) => {
        let baseEndpoint = `${apiRoutes.fundingCalculator}?${isEmail ? 'email' : 'requestId'}=${id}`;
        return baseEndpoint;
      }
    }),
    getfundingCalculatorById: builder.query<any, string>({
      query: (id) => apiRoutes.fundingCalculatorDeatils(id)
    }),
    createFundingCalculataion: builder.mutation<any, any>({
      query: (payload: CreateFundingCalculationPayload) => ({
        url: `${apiRoutes.fundingCalculator}`,
        method: 'PUT',
        body: payload
      }),
    }),
    updateFundingCalculataion: builder.mutation<any, any>({
      query: (payload: UpdateFundingCalculationPayload) => ({
        url: `${apiRoutes.fundingCalculator}/${payload.id}`,
        method: 'PUT',
        body: payload
      }),
    }),
    updateFundingPlayground: builder.mutation<any, any>({
      query: (payload: UpdateFundingCalculationPayload) => ({
        url: `${apiRoutes.fundingCalculator}/calculate?paybackMode=${payload.isDynamicInterestCalculation ? 'DYNAMIC_RETURN_CAP' : 'SIMPLE_INTEREST'}`,
        method: 'POST',
        body: payload
      }),
    })
  })
});

export const {
  useLazyGetfundingCalculatorQuery,
  useLazyGetfundingCalculatorByIdQuery,
  useCreateFundingCalculataionMutation,
  useUpdateFundingCalculataionMutation,
  useUpdateFundingPlaygroundMutation
} = fundingCalculator;
