import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { formatCurrency } from "@utils/common";
import {
  APP_STORE_LINK_REGEX,
  CURRENCY_LIMIT_100K,
  CURRENCY_LIMIT_100M,
  DEFAULT_PAYBACK_PERIOD,
  DEFAULT_PAYBACK_PERIOD_OPTIONS,
  EMAIL_REGEX,
  PLAY_STORE_LINK_REGEX,
  STEAM_STORE_LINK_REGEX,
} from "@constants/constants";
import { DotsLoader } from "@components";
import { NAV_ROUTES } from "@constants/navRoutes";
import useDebounce from "@hooks/useDebounce";
import { CreateFundingCalculationPayload } from "@api/fundingCalculator";
import useCreateFundingCalculation from "@hooks/api/useCreateFundingCalculation";
import headerBackgroundImage from "../../assets/images/v2/hero-bg.png";
import mobileHeaderBackgroundImage from "../../assets/images/v2/hero-bg-mobile.png";
import getEstimateBackgroundImage from "../../assets/images/v2/lead-magnet-bg.png";
import theme from "../../theme";
import { ReactComponent as ArrowIcon } from "../../assets/images/v2/dotted-arrow.svg";
import CalculatorFooter from "../FundingCalculator/components/CalculatorFooter";
import TopBar from "./components/TopBar";
import CustomInputField from "./components/CustomInputField";
import PvXPerksSection from "./components/PvXPerksSection";

const FundingCalculatorLandingPage: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const { createCalculataion } = useCreateFundingCalculation();

  const [checkboxStates, setCheckboxStates] = useState({
    agreeEmails: false,
    agreeTerms: false,
  });
  const [name, setName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [gameLink, setGameLink] = useState<string>("");
  const [avgMonthlyMarketingSpend, setAvgMonthlyMarketingSpend] = useState<
    number | null
  >(null);
  const [errorState, setErrorState] = useState({
    name: false,
    companyName: false,
    email: false,
    gameLink: false,
    avgMonthlyMarketingSpend: false,
  });
  const [toggleLoader, setLoader] = useState<boolean>(false);

  const isLinkFormatValid =
    APP_STORE_LINK_REGEX.test(gameLink) ||
    PLAY_STORE_LINK_REGEX.test(gameLink) ||
    STEAM_STORE_LINK_REGEX.test(gameLink);

  const handleCheckboxChange = (key: "agreeEmails" | "agreeTerms") => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleAvgMonthlyMarketingSpend = (value: string) => {
    const amount = parseFloat(value.replace(/[^0-9]/g, ""));
    if (!isNaN(amount)) {
      setAvgMonthlyMarketingSpend(amount);
    } else {
      setAvgMonthlyMarketingSpend(null);
    }
  };

  const getMonthlyMarketingSpendLabel = () => {
    if (errorState.avgMonthlyMarketingSpend) {
      if (avgMonthlyMarketingSpend) {
        return `${formatCurrency(CURRENCY_LIMIT_100K)} - ${formatCurrency(
          CURRENCY_LIMIT_100M
        )}`;
      } else {
        return "MONTHLY MARKETING SPENDS (USD)";
      }
    } else {
      return "MONTHLY MARKETING SPENDS (USD)";
    }
  };

  const getGameLinkLabel = () => {
    if (errorState.gameLink) {
      if (gameLink) {
        return "INVALID PLAY STORE / APP STORE LINK";
      } else {
        return "LINK TO WEBSITE / PLAY STORE / APP STORE";
      }
    } else {
      return "LINK TO WEBSITE / PLAY STORE / APP STORE";
    }
  };

  const handleGetEstimate = async () => {
    const errors = {
      ...errorState,
      name: Boolean(!name),
      companyName: Boolean(!companyName),
      email: Boolean(!email || !EMAIL_REGEX.test(email)),
      gameLink: Boolean(!gameLink || !isLinkFormatValid),
      avgMonthlyMarketingSpend: Boolean(
        !avgMonthlyMarketingSpend ||
        avgMonthlyMarketingSpend < CURRENCY_LIMIT_100K ||
        avgMonthlyMarketingSpend > CURRENCY_LIMIT_100M
      ),
    };
    setErrorState(errors);

    if (!Boolean(Object.values(errors).some((error) => error))) {
      setLoader(true);

      const searchParams = new URLSearchParams(location.search);
      const utmParam = searchParams.get("utm_source");

      const data: CreateFundingCalculationPayload = {
        gameUrl: gameLink,
        avgMonthlyMarketingSpend: avgMonthlyMarketingSpend || 0,
        requesterName: name,
        companyName: companyName,
        businessEmail: email,
        paybackPeriod: DEFAULT_PAYBACK_PERIOD,
        cumulativeRoas: DEFAULT_PAYBACK_PERIOD_OPTIONS[
          DEFAULT_PAYBACK_PERIOD
        ].map((i) => ({ month: i.month, cumulativeRoas: i.value / 100 })),
        suppressNotification:
          `${process.env.REACT_APP_FUNDING_CALCULATOR_SUPPRESS_NOTIFICATION}` ===
            "true"
            ? true
            : false,
        ...(utmParam ? { attributedChannel: utmParam } : {}),
      };
      const res = await createCalculataion(data);
      if (res?.data?.requestId) {
        navigate(
          `${NAV_ROUTES.FUNDING_CALCULATOR_DETAILS}/${res?.data?.requestId}`
        );
      }
    }
  };

  const handleDebouncedGetEstimate = useDebounce(handleGetEstimate, 800);

  const { agreeEmails, agreeTerms } = checkboxStates;
  const isButtonDisabled = !(agreeEmails && agreeTerms);

  return (
    <main
      id="calculator_landing_container"
      style={{
        width: "100%",
        minHeight: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${isMobile ? mobileHeaderBackgroundImage : headerBackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "1.5rem",
          padding: isMobile ? "16px 32px" : "32px 90px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TopBar />
        <Typography
          sx={{
            marginTop: isMobile ? "90px" : "150px",
            fontSize: isMobile ? "54px" : "62px",
            fontWeight: 600,
            color: "#989CA2",
            lineHeight: isMobile ? "60px" : "66px",
            letterSpacing: isMobile ? "-3px" : "-4.2px",
            textAlign: "center",
          }}
        >
          Looking for <span style={{ color: "#162836" }}>flexible</span> and{" "}
          <span style={{ color: "#162836" }}>scalable</span> {<br />} user
          acquisition funding?
        </Typography>
        <Typography
          sx={{
            fontSize: "28px",
            lineHeight: "26px",
            letterSpacing: "-0.3px",
            color: "#356661",
            width: isMobile ? "100%" : "60%",
            marginTop: "38px",
            marginBottom: "72px",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          Access the most effective credit line for marketing
        </Typography>
      </Box>
      <Box
        sx={{
          padding: isMobile ? "30px 20px" : "120px 48px 96px",
          background: "#F6F8F9",
          marginX: isMobile ? "20px" : "100px",
          marginTop: "120px",
          borderRadius: "24px",
          backgroundImage: `url(${getEstimateBackgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <Typography
          sx={{
            fontSize: isMobile ? "54px" : "62px",
            fontWeight: 600,
            lineHeight: isMobile ? "54px" : "62px",
            letterSpacing: isMobile ? "-3px" : "-4px",
            color: "#162936",
          }}
        >
          <span style={{ color: "#989CA2" }}>Estimate</span> your facility size
          and cash cost
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
            marginTop: isMobile ? "40px" : "60px",
            gap: isMobile ? "2.5rem" : "1rem",
          }}
        >
          <Box sx={{ flex: 3 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                flexWrap: "wrap",
                gap: "16px",
                "& > *": {
                  flex: "1 1 calc(50% - 16px)",
                  maxWidth: isMobile ? "100%" : "calc(50% - 16px)",
                },
              }}
            >
              <CustomInputField
                label="WORK EMAIL ADDRESS"
                value={email}
                onChange={(evt) => {
                  setEmail(evt.target.value);
                  setErrorState((prev) => ({ ...prev, email: false }));
                }}
                error={errorState.email}
              />
              <CustomInputField
                label="FULL NAME"
                value={name}
                onChange={(evt) => {
                  setName(evt.target.value);
                  setErrorState((prev) => ({ ...prev, name: false }));
                }}
                error={errorState.name}
              />
              <CustomInputField
                label="COMPANY NAME"
                value={companyName}
                onChange={(evt) => {
                  setCompanyName(evt.target.value);
                  setErrorState((prev) => ({ ...prev, companyName: false }));
                }}
                error={errorState.companyName}
              />
              <CustomInputField
                label={getGameLinkLabel()}
                value={gameLink}
                onChange={(evt) => {
                  setGameLink(evt.target.value);
                  setErrorState((prev) => ({ ...prev, gameLink: false }));
                }}
                error={errorState.gameLink}
              />
              <CustomInputField
                label={getMonthlyMarketingSpendLabel()}
                value={formatCurrency(avgMonthlyMarketingSpend || 0)}
                onChange={(evt) => {
                  handleAvgMonthlyMarketingSpend(evt.target.value);
                  setErrorState((prev) => ({
                    ...prev,
                    avgMonthlyMarketingSpend: false,
                  }));
                }}
                error={errorState.avgMonthlyMarketingSpend}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "flex-end",
              flex: 2,
            }}
          >
            <Box
              sx={{
                background: "#162936",
                borderRadius: "32px",
                padding: "10px",
                paddingTop: "28px",
                width: isMobile ? "100%" : "fit-content",
              }}
            >
              <Box
                sx={{
                  padding: "24px",
                  paddingTop: "14px",
                  paddingBottom: isMobile ? "24px" : "40px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "32px",
                    lineHeight: "42px",
                    letterSpacing: "-1.2px",
                    color: "white",
                    fontWeight: 500,
                    marginBottom: isMobile ? "24px" : "42px",
                  }}
                >
                  Get Estimate
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                    width: "90%",
                    fontSize: "15px",
                    color: agreeEmails ? "#F6F8F9" : "#F6F8F999",
                    lineHeight: "21px",
                    fontWeight: 550,
                    letterSpacing: "-0.6px",
                    marginBottom: "18px",
                  }}
                >
                  <Checkbox
                    checked={agreeEmails}
                    onChange={() => handleCheckboxChange("agreeEmails")}
                    sx={{
                      padding: "0px",
                      color: "#2F736E",
                      "&.Mui-checked": {
                        color: "#2F736E",
                      },
                    }}
                  />
                  I agree to receive general emails and product offers from PvX
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                    width: "90%",
                    fontSize: "15px",
                    color: agreeTerms ? "#F6F8F9" : "#F6F8F999",
                    lineHeight: "21px",
                    fontWeight: 550,
                    letterSpacing: "-0.6px",
                  }}
                >
                  <Checkbox
                    checked={agreeTerms}
                    onChange={() => handleCheckboxChange("agreeTerms")}
                    sx={{
                      padding: "0px",
                      color: "#2F736E",
                      "&.Mui-checked": {
                        color: "#2F736E",
                      },
                    }}
                  />
                  <span>
                    I agree to the PvX Partners&nbsp;
                    <a
                      style={{ textDecoration: "underline", color: "#F6F8F9" }}
                    >
                      {/* FIXME:  Add redirect URL */}
                      Terms & Conditions
                    </a>
                  </span>
                </Box>
              </Box>
              <Button
                onClick={handleDebouncedGetEstimate}
                disabled={isButtonDisabled}
                sx={{
                  height: "84px",
                  background: isButtonDisabled ? "#F6F8F91A" : "#2F736E",
                  width: "100%",
                  marginTop: "10px",
                  padding: "28px 0px",
                  borderRadius: "24px",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    background: isButtonDisabled ? "#F6F8F91A" : "#2F736E",
                  },
                }}
              >
                {toggleLoader ? (
                  <DotsLoader />
                ) : (
                  <ArrowIcon
                    style={{ opacity: isButtonDisabled ? "20%" : "100%" }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <PvXPerksSection />
      <CalculatorFooter />
    </main>
  );
};

export default FundingCalculatorLandingPage;
