import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Typography, Button, Snackbar, useTheme, useMediaQuery } from '@mui/material';
import { Menu, Table } from '../../components';
import useUserList from '../../hooks/api/useUserList';
import { RootState } from '../../stores/store';
import { NAV_ROUTES } from '../../constants/navRoutes';
import { formatUserList } from '../../utils/common';
import { resetToastMessage, setToastMessage } from '../../stores/App/slice';
import useDebounce from '../../hooks/useDebounce';
import { SortData } from '../../components/Table';
import useRetryCreateUser from '../../hooks/api/useRetryCreateUser';
import { titles } from '@utils/translations/en';
import AddIcon from '@mui/icons-material/Add';

const UserList: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state?.UserData);
  const userList = useSelector((state: RootState) => state?.UserListData);
  const appData = useSelector((state: RootState) => state?.AppData);
  const { reCreateUser } = useRetryCreateUser();
  const { getUserList, isLoading } = useUserList();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<SortData>({ columnId: 'email', sortOrder: 'ASC' });

  // Hook to redirect user if not admin
  useEffect(() => {
    if (userDetails?.user?.id && userDetails?.user?.role !== "ADMIN") {
      navigate(NAV_ROUTES.DASHBOARD);
    }
  }, [userDetails]);

  // Hook to get user list on page load, sorted
  useEffect(() => {
    if (sort.columnId && sort.sortOrder) {
      getUserList({ pageNumber, pageSize, search, sort: sort.columnId, sortOrder: sort.sortOrder });
    }
  }, [
    pageNumber,
    pageSize,
    search,
    sort
  ]);

  const onPageChange = (e: any, page: number) => setPageNumber(page);
  const onRowsPerPageChange = (e: any) => setPageSize(e.target.value.toString());

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedUserId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = async (type: string, id: string) => {
    if (type === 'View User') {
      navigate(`${NAV_ROUTES.USER_MANAGEMENT}/user-details/${id}`);
    } else if (type === 'Retry Creation') {
      const res = await reCreateUser({ id });
      dispatch(setToastMessage(`${res?.message}`));
      navigate(NAV_ROUTES.USER_MANAGEMENT);
    } else if (type === 'Re-Invite User') {
      // TODO: Call api for RE Invite
    } else {
      return null;
    }
  };

  const handleRowClick = (id: string) => {
    navigate(`/user-management/user-details/${id}`);
  };

  const columnHeaders = [
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Role' },
    { id: 'joiningDate', label: 'Joining Date' },
    { id: 'status', label: 'Status' }
  ];

  const getMenuActions = (selectedId: string) => {
    const isPartialUser = userList.userList.find((u) => (u.id === selectedId))?.status === 'PENDING';
    if (isPartialUser) {
      return ['View User', 'Retry Creation'];
    } else {
      return ['View User'];
    }
  };

  const handleDebouncedSearch = useDebounce((search: string) => setSearch(search), 500);

  const handleUpdateSort = (columnId: string, sortOrder: 'ASC' | 'DESC') => {
    setSort({ columnId, sortOrder });
  };

  return (
    <Container
      id='user_list_container'
      sx={{
        height: '100%',
        width: '100%',
        p: 0,
        m: 0,
      }}
    >
      <Box
        id='header_wrapper'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: isMobile ? 0 : 2,
          mb: 2
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            fontSize: "1.5rem",
            fontFamliy: "Manrope, sans-serif",
          }}
        >
          {titles.USERS}
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/user-management/user-invite')}
          sx={{
            borderRadius: '100px',
            fontWeight: '700',
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: '#F6F8F9',
            fontFamily: 'Manrope, sans-serif',
            '&:hover': {
              backgroundColor: theme.palette.primary.main
            },
            padding: '10px 24px'
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem" }}>
            <AddIcon
              htmlColor={theme.palette.common.white}
              sx={{
                height: '1rem',
                width: '1rem',
                cursor: "pointer",
                backgroundColor: "transparent",
              }}
            />
            <Typography
              sx={{
                color: theme.palette.common.white,
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              Invite User
            </Typography>
          </Box>
        </Button>
      </Box>
      <Table
        columns={columnHeaders}
        rows={formatUserList(userList?.userList)}
        loading={isLoading}
        onRowClick={handleRowClick}
        onRowActionClick={handleMenuOpen}
        totalCount={userList?.totalCount || 0}
        currentPage={pageNumber}
        pageSize={pageSize}
        handleChangePage={onPageChange}
        handleChangeRowsPerPage={onRowsPerPageChange}
        handleSearch={(val: string) => handleDebouncedSearch(val)}
        sort={sort}
        handleSort={handleUpdateSort}
      />
      {selectedUserId && (<Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onMenuItemClick={handleClickMenuItem}
        actions={getMenuActions(selectedUserId)}
        value={selectedUserId}
      />)}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(appData?.toast?.toast_message)}
        autoHideDuration={2000}
        onClose={() => dispatch(resetToastMessage())}
        message={`${appData?.toast?.toast_message || ''}`}
      />
    </Container>
  );
};

export default UserList;
