import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import ChartInfoChip from './ChartInfoChip';
import CalculatorChartInput from './CalculatorChartInput';
import { CHART_TAGS } from '../../../constants/constants';
import { LineChart, BarChart, CustomTooltip } from '../../../components';
import { RootState } from '../../../stores/store';
import infoIconQuestion from '../../../assets/images/v2/info_icon_question.svg';
import { ReactComponent as MonthlyProfitsIcon } from '../../../assets/icons/v2/monthly-profits-icon.svg';
import { ReactComponent as CumulativeProfitsIcon } from '../../../assets/icons/v2/cumulative-profits-icon.svg';
import { CalculatorChartData } from '../../../stores/FundingCalculator/types/fundingCalculator';
import { calculateInterpolatedChartData, formatCurrency, percentageDifference } from '../../../utils/common';
import { genericTexts, labels, messages } from '@utils/translations/en';
import FundingCalculatorSummaryWrapper from './FundingCalculatorSummaryWrapper';

interface FundingCalculatorChartBoardPropType {
    toggleLoader: any;
    paybackPeriod: any;
    monthlyExpenditureData: any;
    setMonthlyExpenditureData: any;
    setDynamicInterestCalculation: any;
    isDynamicInterestCalculation: any;
    redrawCount: number;
};

const FundingCalculatorChartBoard: FC<FundingCalculatorChartBoardPropType> = ({
    toggleLoader,
    paybackPeriod,
    monthlyExpenditureData,
    setMonthlyExpenditureData,
    setDynamicInterestCalculation,
    isDynamicInterestCalculation,
    redrawCount
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [isProfitsCumulative, toggleProfitsCumulative] = useState(true);

    const fundingCalculatorData = useSelector(
        (state: RootState) => state?.FundingCalculatorData
    );

    const isChartLoading = Boolean(toggleLoader && !fundingCalculatorData?.fundingCalculations?.id)

    const getRoasChartData = () => {
        if (monthlyExpenditureData.length) {
            return calculateInterpolatedChartData(monthlyExpenditureData);
        } else if (fundingCalculatorData?.fundingCalculations?.charts?.cumulative_roas?.datasets.length) {
            return fundingCalculatorData.fundingCalculations.charts.cumulative_roas.datasets;
        } else {
            return [];
        }
    };

    const getProfitsChartData = useCallback(() => {
        const data = isProfitsCumulative
            ? fundingCalculatorData?.fundingCalculations?.charts?.cumulative_profit_before_taxes?.datasets
            : fundingCalculatorData?.fundingCalculations?.charts?.profit_before_taxes?.datasets;

        if (data.length) {
            return data;
        } else {
            return [];
        }
    }, [isProfitsCumulative, fundingCalculatorData]);

    const getSurplusFund = () => {
        if (fundingCalculatorData?.fundingCalculations?.summary?.eligibleFund) {
            return formatCurrency(fundingCalculatorData.fundingCalculations.summary.eligibleFund, true);
        }

        return '';
    }

    const getCurrentPaybackPeriod = () => {
        if (fundingCalculatorData?.fundingCalculations?.variables?.paybackPeriodInMonths) {
            return fundingCalculatorData.fundingCalculations.variables.paybackPeriodInMonths;
        }

        return '';
    }

    const getCurrentRepaymentPeriod = () => {
        if (fundingCalculatorData?.fundingCalculations?.charts?.interest_paid?.datasets?.length) {
            const data = fundingCalculatorData.fundingCalculations.charts.interest_paid.datasets.find((val) => val.label === 'Cash cost')?.data || [];

            if (data.length) {
                const period = data.filter((val) => val.y > 0);
                return period.length;
            }
        }

        return '';
    }

    const getCashBalanceIncrease = () => {
        if (fundingCalculatorData?.fundingCalculations?.charts?.ending_cash_balance?.datasets?.length) {
            const datasets = fundingCalculatorData.fundingCalculations.charts.ending_cash_balance.datasets;
            const investorData = datasets.find(i => i.label === "With PvX Funding")?.data || [];
            const withoutInvestorData = datasets.find(i => i.label === "Without PvX Funding")?.data || [];
            const investmentBalance = investorData[investorData.length - 1].y;
            const nonInvestmentBalance = withoutInvestorData[withoutInvestorData.length - 1].y;

            return Number(Math.round(percentageDifference(investmentBalance, nonInvestmentBalance))).toFixed(0);
        }

        return '';
    }

    return (
        <>
            <Box
                id="calculator_summary_wrapper"
                sx={{
                    width: "100%",
                    height: "max-content",
                    background: theme.palette.common.white,
                    borderRadius: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    border: "1px solid #E8EAEB",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    mb: "1rem",
                }}
            >
                <FundingCalculatorSummaryWrapper
                    isChartLoading={isChartLoading}
                    toggleLoader={toggleLoader}
                    cashBalanceIncrease={getCashBalanceIncrease()}
                    setDynamicInterestCalculation={setDynamicInterestCalculation}
                    isDynamicInterestCalculation={isDynamicInterestCalculation}
                    redrawCount={redrawCount}
                />
            </Box>
            <Box
                id="chart_canvas_1"
                sx={{
                    width: "100%",
                    height: "max-content",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    border: "1px solid #E8EAEB",
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: 'center',
                    mb: "2rem"
                }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? "0.85rem" : "1.23rem",
                            fontWeight: 800,
                            lineHeight: "1.5rem",
                            fontFamily: "Manrope, sans-serif",
                            color: '#11111F',
                            letterSpacing: '-0.4px',
                            pr: "1rem"
                        }}
                    >
                        {genericTexts.netCumulativeROAS}
                    </Typography>
                    <CustomTooltip
                        content={
                            <>
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 800, fontSize: "0.65rem" }}>
                                    <b>{genericTexts.netCumulativeROAS.toUpperCase()}</b>
                                </div>
                                <br />
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 550, fontSize: "0.8rem" }}>
                                    <b>{messages.fundingCalculatorLanding.netCumulativeROAS}</b> {messages.fundingCalculatorLanding.netCumulativeROAStooltip}
                                </div>
                            </>
                        }
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: isMobile ? "1.5rem" : "2rem",
                                height: isMobile ? "1.5rem" : "2rem",
                                border: `1px solid #989CA270`,
                                borderRadius: "5rem", background: theme.palette.common.white
                            }}
                        >
                            <img src={infoIconQuestion} alt="Info Icon" style={{ width: isMobile ? "0.8rem" : "1rem", height: 'auto' }} />
                        </div>
                    </CustomTooltip>
                </Box>
                <CalculatorChartInput
                    paybackPeriod={paybackPeriod}
                    monthlyPaybackValues={monthlyExpenditureData}
                    handleChangeMonthlyPaybackValues={(data: CalculatorChartData) => setMonthlyExpenditureData(data)}
                    isDynamicInterestCalculation={isDynamicInterestCalculation}
                />
                <Box sx={{ width: "100%", height: '19rem' }} >
                    <LineChart
                        isLoading={isChartLoading}
                        data={getRoasChartData()}
                        type={CHART_TAGS.CUMULATIVE_ROAS}
                    />
                </Box>
            </Box>
            <Box
                id="chart_canvas_2"
                sx={{
                    width: "100%",
                    height: "28.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    border: "1px solid #E8EAEB",
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? "0.85rem" : "1.23rem",
                        fontWeight: 800,
                        lineHeight: "1.5rem",
                        mb: "1rem",
                        fontFamily: "Manrope, sans-serif",
                        color: '#11111F',
                        letterSpacing: '-0.4px'
                    }}
                >
                    {labels.fundingCalculatorPage.monthlyMarketingSpends}
                </Typography>
                {getSurplusFund() && (<ChartInfoChip info={`FREE UP ${getSurplusFund()} IN UA SPENDS OVER THE NEXT ${getCurrentPaybackPeriod()} MONTHS`} />)}
                <BarChart
                    isLoading={isChartLoading}
                    data={
                        fundingCalculatorData?.fundingCalculations?.charts
                            ?.monthly_marketing_spends?.datasets || []
                    }
                    type={CHART_TAGS.MONTHLY_MARKETING_SPENDS_SURPLUS_EXPENDITURE}
                />
            </Box>
            <Box
                id="chart_canvas_3"
                sx={{
                    width: "100%",
                    height: "25rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    border: "1px solid #E8EAEB",
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? "0.85rem" : "1.23rem",
                            fontWeight: 800,
                            lineHeight: "1.5rem",
                            mb: "2rem",
                            fontFamily: "Manrope, sans-serif",
                            color: '#11111F',
                            letterSpacing: '-0.4px'
                        }}
                    >
                        {labels.fundingCalculatorPage.netRevenue}
                    </Typography>
                </Box>
                <BarChart
                    isLoading={isChartLoading}
                    data={fundingCalculatorData?.fundingCalculations?.charts?.revenue?.datasets}
                    type={CHART_TAGS.REVENUE}
                />
            </Box>
            <Box
                id="chart_canvas_4"
                sx={{
                    width: "100%",
                    height: "30rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    border: "1px solid #E8EAEB",
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        gap: isMobile ? '12px' : '24px',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? "0.85rem" : "1.23rem",
                            fontWeight: 800,
                            lineHeight: "1.5rem",
                            mb: isMobile ? "0rem" : 0,
                            fontFamily: "Manrope, sans-serif",
                            color: '#11111F',
                            letterSpacing: '-0.4px'
                        }}
                    >
                        {isProfitsCumulative ? labels.fundingCalculatorPage.cumulativeProfitBeforeTaxes : labels.fundingCalculatorPage.profit}
                    </Typography>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: 'row',
                            gap: isMobile ? "0.25rem" : "1rem",
                            alignItems: "flex-start",
                            justifyContent: "start",
                            mb: isMobile ? "1rem" : "1.5rem",
                        }}
                    >
                        <Button
                            sx={{
                                width: "50%",
                                fontFamily: "Manrope, sans-serif",
                                textTransform: "none",
                                display: 'flex',
                                justifyContent: 'start',
                                gap: '1rem',
                                p: isMobile ? '16px' : '16px 20px 16px 16px',
                                background: isProfitsCumulative ? '#F2F6F7' : "white",
                                color: '#162936',
                                fontWeight: 550,
                                fontSize: '18px',
                                lineHeight: '18px',
                                letterSpacing: '-1px',
                                borderRadius: '20px',
                                border: !isProfitsCumulative ? '1px solid #16293699' : '1px solid #162C36',
                                '&:hover': {
                                    backgroundColor: isProfitsCumulative ? '#F2F6F7' : "white",
                                },
                            }}
                            onClick={() => toggleProfitsCumulative(true)}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: isMobile ? "15px" : "36px",
                                    height: isMobile ? "15px" : "36px",
                                    borderRadius: "10px",
                                    background: isProfitsCumulative ? "#7FBCAD" : "#E0E0E0",
                                }}
                            >
                                <CumulativeProfitsIcon style={{ flexShrink: 0 }} />
                            </Box>
                            {labels.fundingCalculatorPage.cumulativeProfits}
                        </Button>
                        <Button
                            sx={{
                                width: "50%",
                                fontFamily: "Manrope, sans-serif",
                                textTransform: "none",
                                display: 'flex',
                                justifyContent: 'start',
                                gap: '1rem',
                                p: isMobile ? '16px' : '16px 20px 16px 16px',
                                background: !isProfitsCumulative ? '#F2F6F7' : "white",
                                color: '#162936',
                                fontWeight: 550,
                                fontSize: '18px',
                                lineHeight: '20px',
                                letterSpacing: '-1px',
                                borderRadius: '20px',
                                border: isProfitsCumulative ? '1px solid #16293699' : '1px solid #162C36',
                                '&:hover': {
                                    backgroundColor: !isProfitsCumulative ? '#F2F6F7' : "white",
                                },
                            }}
                            onClick={() => toggleProfitsCumulative(false)}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: isMobile ? "15px" : "36px",
                                    height: isMobile ? "15px" : "36px",
                                    borderRadius: "10px",
                                    background: !isProfitsCumulative ? "#7FBCAD" : "#E0E0E0",
                                }}
                            >
                                <MonthlyProfitsIcon />
                            </Box>
                            {labels.fundingCalculatorPage.monthly}
                        </Button>
                    </Box>
                </Box>
                {isProfitsCumulative ?
                    <LineChart
                        isLoading={isChartLoading}
                        data={getProfitsChartData()}
                        type={CHART_TAGS.PROFIT_BEFORE_TAXES_CUMULATIVE}
                    />
                    : <BarChart
                        isLoading={isChartLoading}
                        data={getProfitsChartData()}
                        type={CHART_TAGS.PROFIT_BEFORE_TAXES}
                    />}
            </Box>
            <Box
                id="chart_canvas_5"
                sx={{
                    width: "100%",
                    height: "28.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: theme.palette.common.white,
                    border: "1px solid #E8EAEB",
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? "1rem" : "1.5rem",
                    my: "1rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? "0.85rem" : "1.23rem",
                        fontWeight: 800,
                        lineHeight: "1.5rem",
                        mb: "1rem",
                        fontFamily: "Manrope, sans-serif",
                        color: '#11111F',
                        letterSpacing: '-0.4px'
                    }}
                >
                    {labels.fundingCalculatorPage.endingCashBalance}
                </Typography>
                <ChartInfoChip info={`INCREASE CASH BALANCE BY ${getCashBalanceIncrease()}% OVER THE NEXT ${getCurrentPaybackPeriod()} MONTHS`} />
                <LineChart
                    isLoading={isChartLoading}
                    data={
                        fundingCalculatorData?.fundingCalculations?.charts?.ending_cash_balance
                            ?.datasets || []
                    }
                    type={CHART_TAGS.MONTHLY_MARKETING_SPENDS_BALANCE_DELTA}
                />
            </Box>
            <Box
                id="chart_canvas_6"
                sx={{
                    width: "100%",
                    height: "max-content",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    background: theme.palette.common.white,
                    border: "1px solid #E8EAEB",
                    borderRadius: "1rem",
                    px: isMobile ? "1rem" : "2rem",
                    py: isMobile ? 0 : "1.5rem",
                    mt: "1rem",
                    mb: isMobile ? "1rem" : "2rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? "0.85rem" : "1.23rem",
                        fontWeight: 800,
                        lineHeight: "1.5rem",
                        mb: "1rem",
                        fontFamily: "Manrope, sans-serif",
                        color: '#11111F',
                        letterSpacing: '-0.4px'
                    }}
                >
                    {labels.fundingCalculatorPage.principalAndInterestRepayment}
                </Typography>
                <ChartInfoChip info={`REPAYMENT COMPLETES IN ${getCurrentRepaymentPeriod()} MONTHS`} />
                <Box
                    id="interest-paid-bar-chart"
                    sx={{ height: "20rem", width: "100%" }}
                >
                    <BarChart
                        isLoading={isChartLoading}
                        data={
                            fundingCalculatorData?.fundingCalculations?.charts
                                ?.interest_paid.datasets || []
                        }
                        type={CHART_TAGS.INTEREST_PAID_BAR}
                        metadata={{
                            totalInvestmentInterestPaid: fundingCalculatorData?.fundingCalculations?.summary?.totalInvestmentInterestPaid || 0,
                            totalInvestmentPaybackAmount: fundingCalculatorData?.fundingCalculations?.summary?.totalInvestmentPaybackAmount || 0,
                        }}
                    />
                </Box>
            </Box>
            {isMobile && (
                <Box sx={{ height: '4%', width: '100%' }} />
            )}
        </>
    );
}

export default FundingCalculatorChartBoard;
