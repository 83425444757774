import { FC, useState } from 'react';
import { FullPageLoader } from '../../../components';

interface CustomDashboardProps {
    data: {
        id: string;
        embedId?: string;
        filterQuery?: string;
    } | null
}

const CustomDashboard: FC<CustomDashboardProps> = (props) => {
    const { data: dashboardData } = props;
    const [dashboardLoader, setDashboardLoader] = useState<boolean>(false);

    return (
        <>
            {dashboardLoader && (<FullPageLoader hasSideNav={true} />)}
            <div
                id="pvx-dashboard-container"
                style={{
                    width: '100%',
                    height: '100vh',
                    border: 'none'
                }}
            >
                {`No Dashboard Embedded ( error_id: ${dashboardData?.id})`}
            </div>
        </>
    );
}

export default CustomDashboard;
