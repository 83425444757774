import { Box, TextField, Typography } from "@mui/material";
import React from "react";

interface CustomInputFieldProps {
  label: string;
  value: string | number;
  onChange: (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: boolean
}

const CustomInputField: React.FC<CustomInputFieldProps> = ({
  label,
  value,
  onChange,
  error = true
}) => {
  return (
    <Box
      sx={{
        borderRadius: "20px",
        padding: "22px",
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        border: error ? "1px solid #F6465D" : "1px solid #989CA24D",
        background: "white",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Manrope, sans-serif",
          fontSize: "11px",
          lineHeight: "12px",
          fontWeight: 700,
          color: error ? "#F6465D" :"#162936",
        }}
      >
        {label}
      </Typography>
      <TextField
        value={value}
        onChange={onChange}
        sx={{
          "& .MuiOutlinedInput-root": {
            fontSize: "15px",
            color: "#162936",
            fontWeight: "550",
            lineHeight: "18px",
            letterSpacing: "-0.6px",
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
            "& input": {
              padding: 0,
            },
          },
        }}
      />
    </Box>
  );
};
export default CustomInputField;
