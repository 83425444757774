import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthResponse } from './types';
import UserAuth from './auth';

const MAX_RETRIES = 3;

// Utility function to prepare auth headers
const prepareAuthHeaders = (headers: Headers) => {
  const token = UserAuth.accessToken;
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  return headers;
};

const authQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_KEYCLOAK_V2_BASE_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect`
});

const noAuthBaseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_DOMAIN}`
});

// Main query with retry and re-authentication
const baseQueryWithRetryAndReAuth = async (args: any, api: any, extraOptions: any) => {
  const isMutation = api.type === 'mutation';
  let retries = 0;

  if (!isMutation) {
    // Retry logic for queries
    while (retries < MAX_RETRIES) {
      try {
        const result = await fetchBaseQuery({
          baseUrl: `${process.env.REACT_APP_API_DOMAIN}`,
          prepareHeaders: prepareAuthHeaders,
        })(args, api, extraOptions);

        if (result.error) {
          // Handle Keycloak user missing in BE error
          if (result.error.status === 401 && (result.error.data as any)?.code === 4001) {
            throw new Error('User not found');
          }

          // Handle Keycloak token expiry
          if (result.error.status === 401 && UserAuth.refreshToken) {
            console.warn('Token expired. Attempting re-authentication...');

            // Attempt re-authentication with refresh token
            const reAuthResult = await api.dispatch(
              reAuthApi.endpoints.authenticate.initiate({ refresh_token: UserAuth.refreshToken })
            ).unwrap();

            if (reAuthResult.access_token) {
              console.log('%cRe-authentication successful. Retrying original request...', 'background-color: #06402B; color: white; padding: 5px; border-radius: 5px');
              const { access_token, refresh_token } = reAuthResult;
              UserAuth.setTokens({ access_token, refresh_token });

              // Retry original request with new token
              continue;
            } else {
              throw new Error('Re-authentication failed');
            }
          }

          throw new Error((result.error.data as any)?.message || 'API request error occurred');
        }

        // Return the result if successful
        return result;
      } catch (err) {
        retries++;

        if ((err as Error).message === 'User not found') {
          console.error('User not found');
          window.location.href = '/server-error';
          return;
        }

        if (retries >= MAX_RETRIES) {
          console.error(`Retry limit reached. Error: ${JSON.stringify(err as any)}`);

          // Handle redirect based on error type
          if ((err as any)?.data?.error === 'invalid_grant') {
            window.location.href = '/auth';
          } else {
            window.location.href = '/server-error';
          }

          return { error: { message: 'Failed after maximum retries', error: err } };
        }
      }
    }
  }

  // If it's a mutation, attempt without retries
  if (isMutation) {
    try {
      const result = await fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_DOMAIN}`,
        prepareHeaders: prepareAuthHeaders,
      })(args, api, extraOptions);

      if (result.error) {
        if (result.error.status === 401 && UserAuth.refreshToken) {
          // Attempt re-authentication for mutations
          const reAuthResult = await api.dispatch(
            reAuthApi.endpoints.authenticate.initiate({ refresh_token: UserAuth.refreshToken })
          ).unwrap();

          if (reAuthResult.access_token) {
            const { access_token, refresh_token } = reAuthResult;
            UserAuth.setTokens({ access_token, refresh_token });

            // Retry the mutation with new token
            return await fetchBaseQuery({
              baseUrl: `${process.env.REACT_APP_API_DOMAIN}`,
              prepareHeaders: prepareAuthHeaders,
            })(args, api, extraOptions);
          } else {
            throw new Error('Re-authentication failed for mutation');
          }
        }
        throw new Error((result.error.data as any)?.message || 'Mutation API request error occurred');
      }

      return result;
    } catch (err) {
      return { error: { message: 'Failed mutation attempt', error: err } };
    }
  }
};

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: authQuery,
  endpoints: (builder) => ({
    authenticate: builder.mutation<AuthResponse, { code: string }>({
      query: (data) => ({
        url: 'token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code: data.code,
          redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI || '',
          client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
          client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET || '',
        }).toString(),
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

const reAuthApi = createApi({
  reducerPath: 'reAuthApi',
  baseQuery: authQuery,
  endpoints: (builder) => ({
    authenticate: builder.mutation<any, { refresh_token: string }>({
      query: (data) => ({
        url: 'token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: data.refresh_token,
          client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
          client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET || '',
        }).toString(),
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithRetryAndReAuth as any,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  tagTypes: [
    'user',
    'userList',
    'modules',
    'companyList',
    'fundingCaluclator',
  ]
});

export const noAuthBaseApi = createApi({
  reducerPath: 'noAuthApi',
  baseQuery: noAuthBaseQuery,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

export const { useAuthenticateMutation } = authApi;

export default baseApi;
