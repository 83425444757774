import { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { labels, messages } from "@utils/translations/en";
import CalculatorSummaryArc from "./CalculatorSummaryArc";
import FundingCalculatorSummary from "./FundingCalculatorSummary";
import CalculatorOutputIcon from "../../../assets/icons/v2/calculator-output-icon.svg";
import { ReactComponent as Chevron } from "../../../assets/icons/v2/chevron-down.svg";
import infoIconQuestion from "../../../assets/images/v2/info_icon_question.svg";
import useDebounce from "@hooks/useDebounce";
import { CustomTooltip } from "@components";
import { DEFAULT_PVX_INTEREST, DEFAULT_PVX_FINANCING } from "@constants/constants";

interface FundingCalculatorSummaryWrapperProps {
  isChartLoading: boolean;
  toggleLoader: boolean;
  cashBalanceIncrease: string;
  redrawCount: number;
  setDynamicInterestCalculation: () => void;
  isDynamicInterestCalculation: boolean;
}

const FundingCalculatorSummaryWrapper: React.FC<
  FundingCalculatorSummaryWrapperProps
> = ({
  isChartLoading,
  toggleLoader,
  cashBalanceIncrease,
  redrawCount,
  setDynamicInterestCalculation,
  isDynamicInterestCalculation,
}) => {
    const theme = useTheme();
    const isSmallMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    const [showPricingModelDropdown, setShowPricingModelDropdown] =
      useState<boolean>(false);

    const handleDebouncedChangeCalculationType = useDebounce(
      setDynamicInterestCalculation,
      800
    );

    return (
      <>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "max-content",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              pt: "0.8rem",
              pb: "1.5rem",
              fontSize: "1rem",
              background: theme.palette.common.white,
            }}
          >
            <Box
              sx={{
                width: "max-content",
                height: "max-content",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: "1rem",
                background: theme.palette.common.white,
              }}
            >
              <img
                src={CalculatorOutputIcon}
                alt={"Calculator Output"}
                style={{
                  width: "2rem",
                  height: "2rem",
                  marginRight: "1rem",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: 800,
                  color: "#162936",
                  fontSize: "1.5rem",
                }}
              >
                {isChartLoading ? "Calculating..." : "Calculator Output"}
              </Typography>
            </Box>
            {`${process.env.REACT_APP_DYNAMIC_CALCULATION_FEATURE_FLAG}` ===
              "true" ? (
              <Box
                sx={{
                  width: "max-content",
                  height: "max-content",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: "1rem",
                  background: theme.palette.common.white,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    position: "relative",
                    width: "13rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: isMobile ? "1rem" : 0,
                    mx: isMobile ? "0.5rem" : "1rem",
                    background: "theme.palette.common.white",
                    borderRadius: "20px",
                    py: "0.5rem",
                    px: "1.25rem",
                    color: theme.palette.custom_menu.font,
                    borderColor: "#16293699",
                    letterSpacing: "-3%",
                    fontSize: "1rem",
                    lineHeight: "15px",
                    fontWeight: 600,
                    textTransform: "none",
                    fontFamily: "Manrope, sans-serif",
                    "&:hover": {
                      backgroundColor: "#F6F8F9",
                    },
                  }}
                  onClick={() =>
                    setShowPricingModelDropdown((current) => !current)
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.688rem",
                        lineHeight: "0.75rem",
                        fontWeight: 700,
                        letterSpacing: "-0.2px",
                        color: "#989CA2",
                      }}
                    >
                      PRICING MODEL
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "none",
                        fontSize: "0.875rem",
                        lineHeight: "1rem",
                        letterSpacing: "-0.6px",
                        fontWeight: 500,
                        color: theme.palette.common.black,
                      }}
                      fontFamily="Manrope, sans-serif"
                    >
                      {`${isDynamicInterestCalculation
                        ? labels.fundingCalculatorPage.dynamicCapInterest
                        : labels.fundingCalculatorPage.simpleInterest
                        }`}
                    </Typography>
                  </Box>
                  <Chevron style={{ height: "20px", width: "20px" }} />
                  {showPricingModelDropdown ? (
                    <Box
                      sx={{
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#F2F6F7",
                        top: "60px",
                        left: "0px",
                        width: "100%",
                        borderRadius: "8px",
                        boxShadow: "0px 2px 6px 2px #00000026",
                        zIndex: 50
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleDebouncedChangeCalculationType(false)
                        }
                        sx={{
                          backgroundColor: isDynamicInterestCalculation
                            ? "transparent"
                            : "#9AD6D1",
                          textTransform: "none",
                          paddingX: "0.875rem",
                          paddingY: "1rem",
                          fontSize: "1rem",
                          color: "#1D1B20",
                          "&:hover": {
                            backgroundColor: isDynamicInterestCalculation ? "#9AD6D150" : '#9AD6D1',
                          },
                        }}
                      >
                        Simple Interest
                      </Button>
                      <Button
                        onClick={() => handleDebouncedChangeCalculationType(true)}
                        sx={{
                          backgroundColor: !isDynamicInterestCalculation
                            ? "transparent"
                            : "#9AD6D1",
                          textTransform: "none",
                          paddingX: "0.875rem",
                          paddingY: "1rem",
                          fontSize: "1rem",
                          color: "#1D1B20",
                          "&:hover": {
                            backgroundColor: !isDynamicInterestCalculation ? "#9AD6D150" : '#9AD6D1',
                          },
                        }}
                      >
                        Dynamic Return Cap
                      </Button>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Button>
                <CustomTooltip
                  content={
                    <>
                      <div
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: 800,
                          fontSize: "0.65rem",
                        }}
                      >
                        <b>{`${isDynamicInterestCalculation
                          ? labels.fundingCalculatorPage.dynamicCapInterest.toUpperCase()
                          : labels.fundingCalculatorPage.simpleInterest.toUpperCase()
                          }`}</b>
                      </div>
                      <br />
                      <div
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: 550,
                          fontSize: "0.8rem",
                        }}
                      >
                        {!isDynamicInterestCalculation ? (
                          <>
                            {`Pay a progressive ${DEFAULT_PVX_INTEREST}% interest on what is repaid through the revenue share every month until the principal is fully repaid.`}
                            <br />
                            <br />
                            {`First Month Interest = Month 1 Revenue Share * ${DEFAULT_PVX_INTEREST}%`}
                            <br />
                            {`Second Month Interest = Month 2 Revenue Share * ${DEFAULT_PVX_INTEREST * 2}%`}
                          </>
                        ) : (
                          <>
                            {`Pay a total interest of ${DEFAULT_PVX_INTEREST}% + ${DEFAULT_PVX_FINANCING}% for each month the loan is outstanding.`}
                            <br />
                            <br />
                            {`First Month Interest = ${DEFAULT_PVX_INTEREST}%`}
                            <br />
                            {`Last Month Interest = ${DEFAULT_PVX_INTEREST}% + ${DEFAULT_PVX_FINANCING}% * number of months it takes to fully repay`}
                          </>
                        )}
                        <br />
                      </div>
                    </>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: isSmallMobile ? "1rem" : 0,
                      width: isSmallMobile ? "1.5rem" : "2rem",
                      height: isSmallMobile ? "1.5rem" : "2rem",
                      border: `1px solid #989CA270`,
                      borderRadius: "5rem",
                      background: theme.palette.common.white,
                    }}
                  >
                    <img
                      src={infoIconQuestion}
                      alt="Info Icon"
                      style={{
                        width: isSmallMobile ? "0.8rem" : "1rem",
                        height: "auto",
                      }}
                    />
                  </div>
                </CustomTooltip>
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <CalculatorSummaryArc redrawCount={redrawCount} />
            <FundingCalculatorSummary
              showLoader={toggleLoader}
              cashBalanceIncrease={cashBalanceIncrease}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            mt: "1.5rem",
            fontSize: "0.65rem",
            fontWeight: 800,
            fontFamily: "Manrope, sans-serif",
            color: "#898C8D",
          }}
        >
          <b>{messages.fundingCalculatorLanding.disclaimer}</b>
          {messages.fundingCalculatorLanding.disclaimerMessage}
        </Typography>
      </>
    );
  };

export default FundingCalculatorSummaryWrapper;
