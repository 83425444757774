import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGE_TITLES, NAV_ROUTES } from '@constants/navRoutes';

const usePageTitle = () => {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        const title = Object.entries(PAGE_TITLES).find(([key, value]) => {
            const route = NAV_ROUTES[key as keyof typeof NAV_ROUTES];
            const dynamicRoutePattern = new RegExp(`^${route.replace(/:[^/]+/g, '[^/]+')}$`);
            return dynamicRoutePattern.test(path);
        })?.[1];

        document.title = title || 'PvX Partners';
    }, [location]);
};

export default usePageTitle;
