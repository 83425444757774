import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, Box, Typography, Divider } from '@mui/material';
import { formatCurrency } from '../../../utils/common';
import CurrencyInputField from './CurrencyInputField';
import { FundingCalculatorErrorState } from '../FundingCalculatorPage';
import { RootState } from '../../../stores/store';
import { setFundingCalculator } from '../../../stores/FundingCalculator/slices';
import { CURRENCY_LIMIT_10K, CURRENCY_LIMIT_100M, CURRENCY_LIMIT_100K } from '../../../constants/constants';
import useDebounce from '../../../hooks/useDebounce';
import { labels } from '@utils/translations/en';

type LatestMonthFinancialsPropType = {
    errorState: FundingCalculatorErrorState;
};

const LatestMonthFinancials: FC<LatestMonthFinancialsPropType> = (props) => {
    const { errorState } = props;

    const theme = useTheme();
    const dispatch = useDispatch();
    const fundingCalculatorData = useSelector(
        (state: RootState) => state?.FundingCalculatorData
    );
    const [netRevenue, setNetRevenue] = useState<number>(0);
    const [marketingSpends, setMarketingSpends] = useState<number>(0);
    const [latestMonthCashBalance, setLatestMonthCashBalance] = useState<number>(0);
    const [operatingExpenses, setOperatingExpenses] = useState<number>(0);

    // Hook to update states based on redux store values
    useEffect(() => {
        if (fundingCalculatorData?.fundingCalculations?.id) {
            const latestMonthFinancials = fundingCalculatorData.fundingCalculations.variables.latestMonthFinancials;
            setNetRevenue(latestMonthFinancials?.netRevenue);
            setMarketingSpends(latestMonthFinancials.marketingSpends);
            setLatestMonthCashBalance(latestMonthFinancials.latestMonthCashBalance);
            setOperatingExpenses(latestMonthFinancials.operatingExpenses);
        }
    }, [fundingCalculatorData]);

    // Hook to trigger debounced redux store update on state changes
    useEffect(() => {
        updateFundingCalculatorStore();
    }, [netRevenue, marketingSpends, latestMonthCashBalance, operatingExpenses]);

    // Debounced function to update redux store
    const updateFundingCalculatorStore = useDebounce(() => {
        if (fundingCalculatorData?.fundingCalculations?.id) {
            let profit = netRevenue - (marketingSpends + operatingExpenses);

            let fundingCalculationsToUpdate = { ...fundingCalculatorData?.fundingCalculations };
            let fundingCalculationVariablesToUpdate = { ...fundingCalculationsToUpdate?.variables };

            const updatedLatestMonthFinancials = {
                netRevenue,
                marketingSpends,
                latestMonthCashBalance,
                operatingExpenses,
                profitBeforeTaxes: profit,
            };

            fundingCalculationVariablesToUpdate.latestMonthFinancials = updatedLatestMonthFinancials;
            fundingCalculationsToUpdate.variables = fundingCalculationVariablesToUpdate;

            dispatch(setFundingCalculator(fundingCalculationsToUpdate));
        }
    }, 500);

    const handleLatestMonthFinancialsUpdate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFunction: React.Dispatch<React.SetStateAction<number>>) => {
        const inputValue = e.target.value;
        const numericValue = parseFloat(inputValue.replace(/[^0-9]/g, '')) || 0;
        setFunction(numericValue);
    };

    return (
        <Box
            id="latest-month-financials-fields-wrapper"
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Box
                    id="net-revenue-field"
                    sx={{
                        width: '95%',
                        height: 'fit-content',
                        mt: '0.6rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center'
                    }}
                >
                    <CurrencyInputField
                        label={`${labels.fundingCalculatorPage.netRevenue}`}
                        value={netRevenue}
                        onChange={(e) => handleLatestMonthFinancialsUpdate(e, setNetRevenue)}
                        error={errorState.netRevenue}
                        errorText={netRevenue && errorState.netRevenue ? `Min. ${formatCurrency(CURRENCY_LIMIT_10K)} - Max. ${formatCurrency(CURRENCY_LIMIT_100M)}` : ''}
                    />
                </Box>
                <Box
                    id="marketing-spends-field"
                    sx={{
                        width: '95%',
                        height: 'fit-content',
                        mt: '0.6rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center'
                    }}
                >
                    <CurrencyInputField
                        label={labels.fundingCalculatorPage.marketingSpends}
                        value={marketingSpends}
                        onChange={(e) => handleLatestMonthFinancialsUpdate(e, setMarketingSpends)}
                        error={errorState.marketingSpends}
                        errorText={marketingSpends && errorState.marketingSpends ? `Min. ${formatCurrency(CURRENCY_LIMIT_100K)} - Max. ${formatCurrency(CURRENCY_LIMIT_100M)}` : ''}
                    />
                </Box>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Box
                    id="operating-expenses-field"
                    sx={{
                        width: '95%',
                        height: 'fit-content',
                        mt: '0.6rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center'
                    }}
                >
                    <CurrencyInputField
                        label={labels.fundingCalculatorPage.operatingExpenses}
                        value={operatingExpenses}
                        onChange={(e) => handleLatestMonthFinancialsUpdate(e, setOperatingExpenses)}
                        error={errorState.operatingExpenses}
                        errorText={operatingExpenses && errorState.operatingExpenses ? `Min. ${formatCurrency(CURRENCY_LIMIT_10K)} - Max. ${formatCurrency(CURRENCY_LIMIT_100M)}` : ''}
                    />
                </Box>
                <Box
                    id="latest-month-cash-balance-field"
                    sx={{
                        width: '95%',
                        height: 'fit-content',
                        mt: '0.6rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center'
                    }}
                >
                    <CurrencyInputField
                        label={labels.fundingCalculatorPage.lastMonthCashBalance}
                        value={latestMonthCashBalance}
                        onChange={(e) => handleLatestMonthFinancialsUpdate(e, setLatestMonthCashBalance)}
                        error={errorState.latestMonthCashBalance}
                        errorText={latestMonthCashBalance && errorState.latestMonthCashBalance ? `Min. ${formatCurrency(CURRENCY_LIMIT_10K)} - Max. ${formatCurrency(CURRENCY_LIMIT_100M)}` : ''}
                    />
                </Box>
            </Box>
            <Divider sx={{ background: theme.palette.secondary.main, width: "90%", mt: '1rem', }} />
            <Box
                id="profit-before-taxes-field"
                sx={{
                    width: '95%',
                    height: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: "0.5rem"
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 700,
                        fontSize: '1.5rem',
                        color: theme.palette.funding_calculator.main_page.secondary_font
                    }}
                    fontFamily="Manrope, sans-serif"
                >
                    {`${formatCurrency(fundingCalculatorData?.fundingCalculations?.variables?.latestMonthFinancials?.profitBeforeTaxes)}`}
                </Typography>
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 700,
                        fontSize: '0.8rem',
                        color: "#989CA2"
                    }}
                    fontFamily="Manrope, sans-serif"
                >
                    {labels.fundingCalculatorPage.profit.toUpperCase()}
                </Typography>
            </Box>
            <Divider sx={{ background: theme.palette.secondary.main, width: "90%" }} />
        </Box>
    );
}

export default LatestMonthFinancials;
