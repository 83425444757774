import React from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';

type LoaderProps = {
    customWidth?: string;
};

const Loader: React.FC<LoaderProps> = ({ customWidth }) => {
    const theme = useTheme();

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Box sx={{ width: customWidth || '15%' }}>
                {/* <Player
                    src={loader}
                    className="player"
                    loop
                    autoplay
                /> */}
                <CircularProgress style={{ width: customWidth || '15%', color: theme.palette.primary.main }} />
            </Box>
        </Box>
    );
};

export default Loader;
