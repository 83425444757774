import { PaybackPeriodOptions } from "../stores/FundingCalculator/types/fundingCalculator";

const USER_ROLES = {
    VIEWER: 'Viewer',
    ADMIN: 'Admin'
}

const USER_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'In-Active',
    INVITE_EXPIRED: 'Invite expired',
    INVITED: 'Invited',
    PENDING: 'Failed',
    PENDING_ROLE_UPDATE: 'Pending'
}

const ICON_COLORS = ['#5300A7', '#00866E', '#728600', '#006686', '#00866E', '#861800', '#002686'];

const STATUS_COLORS = {
    succes: {
        color: '#162936',
        background: 'transparent'
    },
    error: {
        color: '#162936',
        background: '#FFD2CC'
    },
    warning: {
        color: '#162936',
        background: '#FFE0B2'
    },
    default: {
        color: '#162936',
        background: '#9AD6D1'
    }
}

const CURRENCY_LIMIT_10K = 10000;
const CURRENCY_LIMIT_100K = 100000;
const CURRENCY_LIMIT_10M = 10000000;
const CURRENCY_LIMIT_100M = 100000000;

const DEFAULT_MARKETING_SPENDS = 250000;
const MULTIPLICATION_FACTOR_DEFAULT_MONTHLY_REVENUE = 2;
const MULTIPLICATION_FACTOR_DEFAULT_OPERATING_EXPENSES = 0.5;
const MULTIPLICATION_FACTOR_DEFAULT_PREVIOUS_MONTH_BALANCE = 1.5;

const DEFAULT_PAYBACK_PERIOD = 12;
const DEFAULT_PVX_INTEREST = 2;
const DEFAULT_PVX_FINANCING = 0.8;
const DEFAULT_REVENUE_CHURN = 0;
const DEFAULT_UA_INCREASE = 0.025;

const ROAS_PAYBACK_PERIOD_VALUE = [
    { value: 1, label: '1' },
    { value: 3, label: '3' },
    { value: 6, label: '6' },
    { value: 9, label: '9' },
    { value: 12, label: '12' },
    { value: 15, label: '15' },
    { value: 18, label: '18' },
    { value: 24, label: '24' },
    { value: 36, label: '36' },
];

const PAYBACK_PERIOD_OPTIONS = [
    { value: 12, label: '12' },
    { value: 18, label: '18' },
    { value: 24, label: '24' },
    { value: 36, label: '36' },
];

const PVX_FINANCING_OPTIONS = [
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
    { value: 50, label: '50' },
    { value: 60, label: '60' },
    { value: 70, label: '70' },
    { value: 80, label: '80' },
];

const REVENUE_CHURN_OPTIONS = [
    { value: 0, label: '0' },
    { value: 5, label: '-5' },
    { value: 10, label: '-10' },
    { value: 15, label: '-15' },
    { value: 20, label: '-20' },
    { value: 25, label: '-25' },
    { value: 30, label: '-30' }
];

const UA_INCREASE_OPTIONS = [
    { value: 0, label: '0' },
    { value: 2.5, label: '' },
    { value: 5, label: '5' },
    { value: 7.5, label: '' },
    { value: 10, label: '10' },
    { value: 12.5, label: '' },
    { value: 15, label: '15' },
];

const DEFAULT_PAYBACK_PERIOD_OPTIONS: PaybackPeriodOptions = {
    12: [
        { month: 1, value: 20 },
        { month: 3, value: 75 },
        { month: 6, value: 100 },
        { month: 9, value: 120 },
        { month: 12, value: 135 },
    ],
    18: [
        { month: 1, value: 20 },
        { month: 3, value: 40 },
        { month: 6, value: 60 },
        { month: 9, value: 80 },
        { month: 12, value: 100 },
        { month: 15, value: 120 },
        { month: 18, value: 140 },
    ],
    24: [
        { month: 1, value: 10 },
        { month: 3, value: 30 },
        { month: 6, value: 50 },
        { month: 9, value: 70 },
        { month: 12, value: 90 },
        { month: 15, value: 105 },
        { month: 18, value: 120 },
        { month: 24, value: 140 },
    ],
    36: [
        { month: 1, value: 5 },
        { month: 3, value: 15 },
        { month: 6, value: 25 },
        { month: 9, value: 35 },
        { month: 12, value: 45 },
        { month: 15, value: 65 },
        { month: 18, value: 85 },
        { month: 24, value: 110 },
        { month: 36, value: 140 },
    ],
};

const DEFAULT_LATEST_MONTH_FINANCIALS = {
    netRevenue: 0,
    marketingSpends: 0,
    latestMonthCashBalance: 0,
    operatingExpenses: 0,
    profitBeforeTaxes: 0,
};

const CHART_TAGS = {
    CUMULATIVE_ROAS: 'Cumulative ROAS',
    MONTHLY_MARKETING_SPENDS_SURPLUS_EXPENDITURE: 'Monthly Marketing Spends - surplus expenditure',
    MONTHLY_MARKETING_SPENDS_BALANCE_DELTA: 'Monthly Marketing Spends - balance delta',
    PROFIT_BEFORE_TAXES: 'Profit Before Taxes',
    PROFIT_BEFORE_TAXES_CUMULATIVE: 'Profit Before Taxes Cumulative',
    INTEREST_PAID_BAR: 'Interest Paid - BAR',
    INTEREST_PAID_PIE: 'Interest Paid - PIE',
    REVENUE: 'Revenue',
};

const fundingPerks = [
    "Receive up to 80% of your marketing budget in cash every month",
    "Funding is collateralised by only the cohorts within the PvX funding period",
    "Pay a monthly revenue share proportionate to the amount of funding received, capped at 100% + Cash Cost",
    "Request up to a $100M facility to draw down upon as you scale UA spends",
];

const APP_STORE_LINK_REGEX = /^https:\/\/apps\.apple\.com\/[a-z]{2}\/app\/[a-zA-Z0-9\-]+\/id\d{9,}$/
const PLAY_STORE_LINK_REGEX = /^https:\/\/play\.google\.com\/store\/apps\/details\?id=[a-zA-Z0-9\.]+(&[a-zA-Z0-9=_-]+)*$/
const STEAM_STORE_LINK_REGEX = /^https:\/\/store\.steampowered\.com\/app\/\d+\/[a-zA-Z0-9_]+\/?$/
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export {
    USER_ROLES,
    USER_STATUS,
    ICON_COLORS,
    STATUS_COLORS,
    ROAS_PAYBACK_PERIOD_VALUE,
    PAYBACK_PERIOD_OPTIONS,
    DEFAULT_PAYBACK_PERIOD,
    PVX_FINANCING_OPTIONS,
    DEFAULT_PVX_INTEREST,
    DEFAULT_PVX_FINANCING,
    DEFAULT_REVENUE_CHURN,
    REVENUE_CHURN_OPTIONS,
    DEFAULT_UA_INCREASE,
    UA_INCREASE_OPTIONS,
    DEFAULT_PAYBACK_PERIOD_OPTIONS,
    CHART_TAGS,
    DEFAULT_LATEST_MONTH_FINANCIALS,
    DEFAULT_MARKETING_SPENDS,
    fundingPerks,
    APP_STORE_LINK_REGEX,
    PLAY_STORE_LINK_REGEX,
    STEAM_STORE_LINK_REGEX,
    EMAIL_REGEX,
    CURRENCY_LIMIT_10K,
    CURRENCY_LIMIT_100K,
    CURRENCY_LIMIT_10M,
    CURRENCY_LIMIT_100M,
    MULTIPLICATION_FACTOR_DEFAULT_MONTHLY_REVENUE,
    MULTIPLICATION_FACTOR_DEFAULT_OPERATING_EXPENSES,
    MULTIPLICATION_FACTOR_DEFAULT_PREVIOUS_MONTH_BALANCE
}
