import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TablePagination,
  useTheme,
  InputBase,
  Divider,
  Pagination,
  useMediaQuery
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../Loader';
import { ICON_COLORS, STATUS_COLORS, USER_STATUS } from '../../constants/constants';
import { ReactComponent as ActionIcon } from '../../assets/icons/three-dots.svg';
import { setToastMessage } from '../../stores/App/slice';
import SortButtonGroup from '../SortButtonGroup/SortButtonGroup';
import { genericTexts } from '@utils/translations/en';

interface Column {
  id: string;
  label: string;
  numeric?: boolean;
}

interface Data {
  [key: string]: any;
}

export interface SortData {
  columnId: string;
  sortOrder: 'ASC' | 'DESC';
}

interface TableProps {
  rows: Data[];
  columns: Column[];
  loading: boolean;
  onRowClick: (id: string) => void;
  onRowActionClick?: (event: React.MouseEvent<HTMLElement>, id: string) => void;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (val: string) => void;
  sort: SortData;
  handleSort: (columnId: string, sortOrder: 'ASC' | 'DESC') => void;
}

const Table: React.FC<TableProps> = ({
  rows,
  columns,
  loading,
  onRowClick,
  onRowActionClick,
  totalCount,
  currentPage,
  pageSize,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  sort,
  handleSort
}) => {
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    handleSearch(search);
  }, [search])

  const disabledUserStatus = [USER_STATUS.PENDING];

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Active':
        return STATUS_COLORS.succes;
      case 'Failed':
        return STATUS_COLORS.error;
      case 'Pending':
        return STATUS_COLORS.warning;
      default:
        return STATUS_COLORS.default
    }
  };

  const getCell = (columnId: string, row: Data, index: number) => {
    if (columnId === 'email') {
      return (
        <>
          <Box
            sx={{
              fontWeight: '800',
              borderRadius: '50%',
              padding: 0,
              width: '2rem',
              height: '2rem',
              minWidth: 'unset',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: theme.palette.common.white,
              backgroundColor: theme.palette.custom_menu.hover,
              marginRight: '0.5rem'
            }}
          >
            {row[columnId]?.[0]?.toUpperCase() || '-'}
          </Box>
          <Box
            sx={{
              maxWidth: isMediumView ? '5rem' : '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {row[columnId].element || row[columnId]}
          </Box>
        </>
      );
    } else if (columnId === 'status') {
      return (
        <Box
          sx={{
            borderRadius: '0.5rem',
            fontWeight: 500,
            width: '5rem',
            height: '2rem',
            textAlign: 'center',
            alignContent: 'center',
            ...getStatusColor(row[columnId].element || row[columnId]),
            maxWidth: isMediumView ? '5rem' : '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {row[columnId].element || row[columnId]}
        </Box>
      );
    } else {
      return row[columnId].element || row[columnId];
    }
  }

  const handleSearchFocus = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  return (
    <>
      <Paper
        sx={{
          boxShadow: 'none',
          borderRadius: '16px',
          border: '1px solid #D1D5DB',
        }}
      >
        <Box
          sx={{
            m: isMediumView ? 1 : '10px'
          }}
        >
          <Paper
            component="form"
            sx={{
              height: 45,
              px: "19px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "28px",
              mb: "10px",
              backgroundColor: theme.palette.custom_field_background,
              width: isMediumView ? '100%' : '60%',
              boxShadow: 'none'
            }}
          >
            <InputBase
              inputRef={searchInputRef}
              placeholder={isMediumView ? `Search ...` : `Search Email / Role`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              sx={{
                maxWidth: isMediumView ? '5rem' : '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            />
            {search ? (
              <Box sx={{ mt: 1, cursor: 'pointer' }} onClick={(e) => setSearch('')}>
                <CloseIcon />
              </Box>
            ) : (
              <Box sx={{ mt: 1, cursor: 'pointer' }} onClick={(e) => handleSearchFocus()}>
                <SearchIcon />
              </Box>
            )}
          </Paper>
        </Box>
        <Divider />
        <TableContainer
          id='table_component_container'
          sx={{
            height: `calc(100vh - ${isMediumView ? '244' : '234'}px)`,
            overflow: 'auto'
          }}
        >
          <MuiTable sx={{ border: "none" }} stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, columnHeadIndex) => (
                  <TableCell
                    key={`header-${column.id}`}
                    sx={{
                      textAlign: columnHeadIndex ? 'center' : 'flex-start'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: isMediumView ? 'space-around' : 'space-between',
                        maxHeight: '20px',
                        maxWidth: isMediumView ? '8rem' : '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {column.label.toUpperCase()}
                      <SortButtonGroup
                        columnId={column.id}
                        sortOrder={sort.columnId === column.id ? sort.sortOrder : null}
                        onSort={handleSort}
                      />
                    </Box>
                  </TableCell>
                ))}
                {onRowActionClick && <TableCell key='header-actions'></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.custom_menu.hover
                      },
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      if (disabledUserStatus.includes(row.status)) {
                        dispatch(setToastMessage(`Could not create ${row.email}, please retry.`))
                        return;
                      }
                      onRowClick(row.id)
                    }}
                  >
                    {columns.map((column, columnIndex) => (
                      <TableCell
                        key={`cell-${row.id}-${column.id}`}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                          }}
                        >
                          {getCell(column.id, row, index)}
                        </Box>
                      </TableCell>
                    ))}
                    {onRowActionClick && (
                      <TableCell
                        key={`cell-action-${row.id}`}
                      >
                        <Button
                          variant='outlined'
                          sx={{
                            fontWeight: '800',
                            borderRadius: '50%',
                            padding: 0,
                            width: isMediumView ? '1.5rem' : '2rem',
                            height: isMediumView ? '1.5rem' : '2rem',
                            minWidth: 'unset',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            boxShadow: 'none',
                            '&:hover': {
                              backgroundColor: theme.palette.custom_menu.hover,
                              borderColor: theme.palette.custom_menu.hover,
                            }
                          }}
                          onClick={(e) => onRowActionClick(e, row.id)}
                        >
                          <ActionIcon
                            style={{
                              height: isMediumView ? '10px' : '15px',
                              width: isMediumView ? '10px' : '15px',
                              transform: 'rotate(90deg)'
                            }}
                          />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                !loading && (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} align="center">
                      {genericTexts.noDataAvailable}
                    </TableCell>
                  </TableRow>
                )
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </Paper>
      {totalCount > 0 ? (
        isMediumView ? (
          <Box
            sx={{
              px: 2,
              my: 1,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={Math.ceil(totalCount / pageSize)}
              page={currentPage + 1}
              onChange={(_, page) => handleChangePage(null, page - 1)}
            />
          </Box>
        ) : (
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default Table;
