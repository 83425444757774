import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, useMediaQuery, Drawer, List, ListItem, ListItemText, Typography, Button, Box, Slider } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as InfoIconBlue } from '../../../assets/images/v2/info_icon_blue.svg';
import {
    PVX_FINANCING_OPTIONS,
    UA_INCREASE_OPTIONS,
    PAYBACK_PERIOD_OPTIONS
} from '../../../constants/constants';
import { CustomTooltip, DotsLoader } from '../../../components';
import { RootState } from '../../../stores/store';
import { setFundingCalculator } from '../../../stores/FundingCalculator/slices';
import LatestMonthFinancials from './LatestMonthFinancials';
import { FundingCalculatorErrorState } from '../FundingCalculatorPage';
import FundingCalculatorSlider from './FundingCalculatorSlider';
import useDebounce from '../../../hooks/useDebounce';
import { genericTexts, labels, messages } from '@utils/translations/en';
import { ReactComponent as ArrowIcon } from '../../../assets/images/v2/dotted-arrow.svg';
import { ReactComponent as ListItemIcon } from '../../../assets/images/v2/slider-list-item.svg';
import Gear from '../../../assets/icons/gear.svg';

interface FundingCalculatorSideNavProps {
    showLoader: boolean;
    handleRecalculateFunding: () => void;
    errorState: FundingCalculatorErrorState;
    setMobileSidePanel?: (val: boolean) => void;
}

const FundingCalculatorSideNav: FC<FundingCalculatorSideNavProps> = ({
    showLoader,
    handleRecalculateFunding,
    errorState,
    setMobileSidePanel,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const fundingCalculatorData = useSelector(
        (state: RootState) => state?.FundingCalculatorData
    );

    const handleUpdateFundingVariables = (value: number | number[], fieldName: string) => {
        const numericValue = fieldName === 'paybackPeriodInMonths' ? (value as number) : (value as number) / 100;
        let fundingCalculationsToUpdate = { ...fundingCalculatorData?.fundingCalculations };
        let fundingCalculationVariables = { ...fundingCalculationsToUpdate?.variables };
        const updatedFundingCalculationVariables = {
            ...fundingCalculationVariables,
            [fieldName]: numericValue
        };

        fundingCalculationsToUpdate.variables = updatedFundingCalculationVariables;

        dispatch(setFundingCalculator(fundingCalculationsToUpdate));
    };

    const handleClickCalculate = () => {
        handleRecalculateFunding();

        if (setMobileSidePanel) {
            setMobileSidePanel(false);
        }
    };

    const debouncedHandleClickCalculate = useDebounce(handleClickCalculate, 800);

    return (
        <Drawer
            id="pvx-funding-calculator-panel"
            variant="permanent"
            sx={{
                height: '100%',
                width: isMobile ? '100%' : '25rem',
                '& .MuiDrawer-paper': {
                    position: 'relative',
                    height: '100%',
                    width: isMobile ? '100%' : '25rem',
                    background: "linear-gradient(to right, #181A1C, #222D2E)",
                    color: '#fff',
                    borderRadius: '1rem',
                    px: "1rem",
                    py: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                },
            }}
        >
            {isMobile && setMobileSidePanel && (
                <Box sx={{ width: "100%", height: "max-content", display: "flex", justifyContent: "flex-end" }}>
                    <Box
                        sx={{
                            height: '1.5rem',
                            width: '1.5rem',
                            display: 'flex',
                            borderRadius: "1rem",
                            background: "#FFFFFF50",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CloseIcon
                            sx={{
                                height: '1.25rem',
                                width: '1.25rem',
                                color: theme.palette.common.white
                            }}
                            onClick={() => setMobileSidePanel(false)}
                        />
                    </Box>
                </Box>
            )}
            <List
                id="pvx-funding-variables-section"
                sx={{
                    height: 'calc(100% - 4rem)',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "max-content",
                        color: theme.palette.common.white,
                        display: 'flex',
                        justifyContent: isMobile ? "center" : "flex-start",
                        alignItems: 'center',
                        mb: '1rem',
                        py: '1rem',
                        fontSize: '1rem',
                        background: '#223C3B',
                        borderRadius: "1rem",
                        position: "relative",
                        "::before": {
                            content: '""',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            borderRadius: "1rem",
                            padding: "1px",
                            background: "linear-gradient(-10deg, #284440, #FFFFFF50)",
                            WebkitMask:
                                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                            maskComposite: "exclude",
                        },
                    }}
                >
                    <img
                        src={Gear}
                        alt={genericTexts.refreshChart}
                        style={{
                            width: '2rem',
                            height: '2rem',
                            marginLeft: isMobile ? 0 : "1rem"
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: '1rem',
                            fontWeight: "700",
                            fontFamily: "Manrope, sans-serif",
                            marginLeft: '0.5rem'
                        }}
                    >
                        {labels.fundingCalculatorPage.fundingSettings}
                    </Typography>
                </Box>
                <ListItem
                    id="pvx-pvxFinancing-section"
                    sx={{
                        p: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <ListItemText
                        primary={
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                <ListItemIcon style={{ height: "1.5rem" }} />
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.secondary_font,
                                        fontWeight: 600,
                                        fontSize: '0.8rem'
                                    }}
                                    fontFamily="Manrope, sans-serif"
                                >
                                    {genericTexts.pvxFunding}
                                </Typography>
                            </Box>
                        }
                    />
                    <CustomTooltip
                        content={
                            <>
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 800, fontSize: "0.65rem" }}>
                                    <b>{genericTexts.pvxFunding.toUpperCase()}</b>
                                </div>
                                <br />
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 550, fontSize: "0.8rem" }}>
                                    {messages.fundingCalculatorPage.pvxFundingTooltip}
                                </div>
                            </>
                        }>
                        <InfoIconBlue style={{ width: '1rem', height: 'auto', paddingTop: '1px', paddingRight: '3px' }} />
                    </CustomTooltip>
                </ListItem>
                <FundingCalculatorSlider
                    id="pvx-pvxFinancing-slider"
                    value={(fundingCalculatorData?.fundingCalculations?.variables?.pvxFinancing * 100)}
                    onChange={(e, value) => handleUpdateFundingVariables(value, 'pvxFinancing')}
                    formatSelectedValue={(val) => (`${Number(val)}%`)}
                    step={10}
                    defaultOptions={PVX_FINANCING_OPTIONS.map((option, index) => {
                        if (index % 2 === 0) return option;
                        else return { ...option, label: '' }
                    })}
                    error={errorState.pvxFinancing}
                />
                <ListItem
                    id="pvx-monthly-ua-increase-section"
                    sx={{
                        mt: '0.5rem',
                        p: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <ListItemText
                        primary={
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                <ListItemIcon style={{ height: "1.5rem" }} />
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.secondary_font,
                                        fontWeight: 600,
                                        fontSize: '0.8rem'
                                    }}
                                    fontFamily="Manrope, sans-serif"
                                >
                                    {labels.fundingCalculatorPage.monthlyUAIncrease}
                                </Typography>
                            </Box>
                        }
                    />
                    <CustomTooltip
                        content={
                            <>
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 800, fontSize: "0.65rem" }}>
                                    <b>{labels.fundingCalculatorPage.monthlyUAIncrease.toUpperCase()}</b>
                                </div>
                                <br />
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 550, fontSize: "0.8rem" }}>
                                    {messages.fundingCalculatorPage.monthlyUAIncreaseTooltip}
                                </div>
                            </>
                        }>
                        <InfoIconBlue style={{ width: '1rem', height: 'auto', paddingTop: '1px', paddingRight: '3px' }} />
                    </CustomTooltip>
                </ListItem>
                <FundingCalculatorSlider
                    id="pvx-ua-increase-slider"
                    value={(fundingCalculatorData?.fundingCalculations?.variables.uaIncreasePerMonth * 100)}
                    onChange={(e, value) => handleUpdateFundingVariables(value, 'uaIncreasePerMonth')}
                    formatSelectedValue={(val) => (`${Number(val)}%`)}
                    step={2.5}
                    defaultOptions={UA_INCREASE_OPTIONS}
                    error={errorState.uaIncreasePerMonth}
                />
                <ListItem
                    id="pvx-payback-period-section"
                    sx={{
                        mt: '0.5rem',
                        p: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <ListItemText
                        primary={
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                <ListItemIcon style={{ height: "1.5rem" }} />
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.secondary_font,
                                        fontWeight: 600,
                                        fontSize: '0.8rem'
                                    }}
                                    fontFamily="Manrope, sans-serif"
                                >
                                    {labels.fundingCalculatorPage.paybackPeriod}
                                </Typography>
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.secondary_font,
                                        fontWeight: 400,
                                        fontSize: '0.7rem'
                                    }}
                                    fontFamily="Manrope, sans-serif"
                                >
                                    {genericTexts.inMonths}
                                </Typography>
                            </Box>
                        }
                    />
                    <CustomTooltip
                        content={
                            <>
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 800, fontSize: "0.65rem" }}>
                                    <b>{labels.fundingCalculatorPage.paybackPeriod.toUpperCase()}</b>
                                </div>
                                <br />
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 550, fontSize: "0.8rem" }}>
                                    {messages.fundingCalculatorPage.paybackPeriodTooltip1}
                                    <br />
                                    <br />
                                    {messages.fundingCalculatorPage.paybackPeriodTooltip2}
                                </div>
                            </>
                        }
                    >
                        <InfoIconBlue style={{ width: '1rem', height: 'auto', paddingTop: '1px', paddingRight: '3px' }} />
                    </CustomTooltip>
                </ListItem>
                <Box
                    sx={{
                        width: isMobile ? "100%" : "23rem",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        px: "0.4rem"
                    }}
                >
                    <Typography
                        sx={{
                            width: '1rem',
                            textAlign: "center",
                            color: "#989CA2"
                        }}
                        fontFamily="Manrope, sans-serif"
                        fontSize="0.7rem"
                        fontWeight={500}
                    >
                        {`<${PAYBACK_PERIOD_OPTIONS[0].label}`}
                    </Typography>
                    <Slider
                        value={PAYBACK_PERIOD_OPTIONS.findIndex((opt) => opt.value === fundingCalculatorData?.fundingCalculations?.variables?.paybackPeriodInMonths)}
                        onChange={(e, index) => handleUpdateFundingVariables(PAYBACK_PERIOD_OPTIONS[index as number].value, 'paybackPeriodInMonths')}
                        aria-label="Always visible"
                        aria-labelledby="pvx-payback-period-slider"
                        valueLabelFormat={(index: number) => (`${PAYBACK_PERIOD_OPTIONS[index].value} Months`)}
                        step={null}
                        marks={PAYBACK_PERIOD_OPTIONS.map((opt, index) => ({ value: index, label: `<${opt.label}` }))}
                        min={0}
                        max={PAYBACK_PERIOD_OPTIONS.length - 1}
                        valueLabelDisplay="on"
                        defaultValue={PAYBACK_PERIOD_OPTIONS.findIndex((opt) => opt.value === fundingCalculatorData?.fundingCalculations?.variables?.paybackPeriodInMonths)}
                        sx={{
                            width: '100%',
                            my: '1rem',
                            mx: '0.8rem',
                            '& .MuiSlider-valueLabel': {
                                backgroundColor: "transparent",
                                color: theme.palette.common.white,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: '0.7rem',
                                fontWeight: 700,
                                top: 48,
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: -6,
                                    left: '49%',
                                    transform: 'translateX(-50%)',
                                    width: 10,
                                    height: 10,
                                    borderLeft: '8px solid transparent',
                                    borderRight: '8px solid transparent',
                                    borderBottom: '8px solid #898C8D',
                                    borderRadius: '4px'
                                },
                            },
                            '& .MuiSlider-markLabel': {
                                display: 'none',
                            },
                            '& .MuiSlider-mark': {
                                width: 0,
                                height: 0,
                            },
                            '& .MuiSlider-track': {
                                backgroundImage: "transparent",
                                color: "transparent"
                            },
                            '& .MuiSlider-rail': {
                                backgroundImage: "linear-gradient(to right, #162936, #2F736E, #F2F6F7)",
                                opacity: 1,
                                height: "0.4rem",
                            },
                            '& .MuiSlider-thumb': {
                                height: '1rem',
                                width: '1rem',
                                border: '2px solid',
                                borderColor: "#ACBAA8",
                                backgroundImage: "radial-gradient(circle at center, #F7F7F7, #C9C7C7)",
                            }
                        }}
                    />
                    <Typography
                        sx={{
                            width: '1rem',
                            textAlign: "center",
                            color: "#989CA2"
                        }}
                        fontFamily="Manrope, sans-serif"
                        fontSize="0.7rem"
                        fontWeight={500}
                    >
                        {`<${PAYBACK_PERIOD_OPTIONS[PAYBACK_PERIOD_OPTIONS.length - 1].label}`}
                    </Typography>
                </Box >
                <ListItem
                    id="pvx-latest-month-financials-section"
                    sx={{
                        p: 0,
                        pt: "1rem",
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <ListItemText
                        primary={
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                <ListItemIcon style={{ height: "1.5rem" }} />
                                <Typography
                                    component="span"
                                    style={{
                                        color: theme.palette.funding_calculator.main_page.secondary_font,
                                        fontWeight: 600,
                                        fontSize: '0.8rem'
                                    }}
                                    fontFamily="Manrope, sans-serif"
                                >
                                    {labels.fundingCalculatorPage.lastMonthFinancials}
                                </Typography>
                            </Box>
                        }
                    />
                    <CustomTooltip
                        content={
                            <>
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 800, fontSize: "0.65rem" }}>
                                    <b>{labels.fundingCalculatorPage.lastMonthFinancials.toUpperCase()}</b>
                                </div>
                                <br />
                                <div style={{ fontFamily: "Manrope, sans-serif", fontWeight: 550, fontSize: "0.8rem" }}>
                                    <b>{labels.fundingCalculatorPage.netRevenue}:</b> {messages.fundingCalculatorPage.latestMonthFinancialsTooltip1}
                                    <br />
                                    <br />
                                    <b>{labels.fundingCalculatorPage.marketingSpends}:</b> {messages.fundingCalculatorPage.latestMonthFinancialsTooltip2}
                                    <br />
                                    <br />
                                    <b>{labels.fundingCalculatorPage.operatingExpenses}:</b> {messages.fundingCalculatorPage.latestMonthFinancialsTooltip3}
                                    <br />
                                    <br />
                                    <b>{labels.fundingCalculatorPage.lastMonthCashBalance}:</b> {messages.fundingCalculatorPage.latestMonthFinancialsTooltip4}
                                </div>
                            </>
                        }>
                        <InfoIconBlue style={{ width: '1rem', height: 'auto', paddingTop: '1px', paddingRight: '3px' }} />
                    </CustomTooltip>
                </ListItem>
                <LatestMonthFinancials errorState={errorState} />
            </List>
            <Box
                id="recalculate-funding-data-section"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: 'fit-content',
                    pb: isMobile ? '0.8rem' : 0,
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        width: '95%',
                        height: '3rem',
                        display: 'flex',
                        backgroundColor: "#7FBCAD",
                        color: theme.palette.common.white,
                        borderRadius: "0.75rem",
                        p: 0,
                        m: 0,
                        '&:disabled': {
                            backgroundColor: theme.palette.funding_calculator.main_page.font,
                            color: theme.palette.common.black,
                        },
                    }}
                    onClick={debouncedHandleClickCalculate}
                    disabled={Boolean(Object.values(errorState).some((error) => error))}
                >
                    {showLoader ? (
                        <DotsLoader
                            size={8}
                        />
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                width: '100%',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            <Typography
                                sx={{
                                    textTransform: "none",
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    color: theme.palette.common.white,
                                }}
                                fontFamily="Manrope, sans-serif"
                            >
                                {labels.fundingCalculatorPage.recalculate}
                            </Typography>
                            <ArrowIcon style={{ height: "1rem" }} />
                        </Box>
                    )}
                </Button>
            </Box>
        </Drawer >
    );
};

export default FundingCalculatorSideNav;

